import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Button, IconButton } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    LinearProgress,
    TextField,
    Avatar
} from '@material-ui/core';
import _ from 'underscore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VODSubCategory from './VODSubCategory';
// import Collapse from '@material-ui/core/Collapse';
import validate from 'validate.js';
import { firestore_db } from '../../config/firebase_config.js';
import { Delete } from '@material-ui/icons';
import FileUploader from 'react-firebase-file-uploader';
import firebase from 'firebase';

const schema = {
    name: {
        presence: { allowEmpty: false, message: 'is required' }
    }
};

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 4
    },
    item: {
        height: '100%'
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`
    };
}

class AddNewVODCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddDialog: false,
            values: {
                name: '',
                description: '',
                cat_image: ''
            },
            touched: {
                name: false,
                description: false
            },
            errors: {
                name: null,
                description: null
            },
            isValid: false,
            isLoading: false,
            submitError: null,
            imageUploadProgress: 0,
            isUploading: false
        };
        this.handlePhotoUploadStart = this.handlePhotoUploadStart.bind(this);
        this.handlePhotoUploadProgress = this.handlePhotoUploadProgress.bind(
            this
        );
        this.handlePhotoUploadError = this.handlePhotoUploadError.bind(this);
        this.handlePhotoUploadSuccess = this.handlePhotoUploadSuccess.bind(
            this
        );
    }

    validateForm = _.debounce(() => {
        const { values } = this.state;

        const newState = { ...this.state };
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = errors ? false : true;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };

        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    _addNewCat = async () => {
        const { showAddDialog } = this.state;
        this.setState({
            showAddDialog: !showAddDialog
        });
    };

    _submitForm = async () => {
        const { values } = this.state;
        console.log(values);
        let data = {
            category_name: values.name,
            description: values.description,
            cat_image: values.cat_image,
            create_date: Date.now(),
            subData: []
        };
        await firestore_db
            .collection('main_vod_categories')
            .doc()
            .set(data);
        this._addNewCat();
        // window.location.reload();
        this.props.getData();
    };

    handlePhotoUploadStart() {
        this.setState({ isUploading: true, progress: 0 });
    }
    handlePhotoUploadProgress(progress) {
        this.setState({ imageUploadProgress: progress });
    }
    handlePhotoUploadError(error) {
        this.setState({ isUploading: false });
    }
    handlePhotoUploadSuccess(filename) {
        this.setState({
            filename: filename,
            progress: 100,
            isUploading: false
        });
        firebase
            .storage()
            .ref('Photo')
            .child(filename)
            .getDownloadURL()
            .then(url => this.handleFieldChange('cat_image', url));
    }

    render() {
        const {
            showAddDialog,
            values,
            touched,
            errors,
            isValid,
            submitError,
            isLoading
        } = this.state;

        const showNameError = touched.name && errors.name;
        const showDescriptionError = touched.description && errors.description;

        return (
            <>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this._addNewCat}>
                    Add new VOD Category
                </Button>
                <Dialog open={showAddDialog} fullWidth maxWidth={'sm'}>
                    <DialogTitle>
                        <Typography component="h3" variant="h3">
                            Add New VOD Category
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <TextField
                                style={{
                                    margin: '5px 10px'
                                }}
                                fullWidth
                                label="Category Name"
                                name="name"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'name',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.name}
                                variant="outlined"
                            />
                            {showNameError && (
                                <Typography variant="body2">
                                    {errors.name[0]}
                                </Typography>
                            )}
                            <TextField
                                style={{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Description"
                                name="description"
                                rows={5}
                                multiline
                                onChange={event =>
                                    this.handleFieldChange(
                                        'description',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.description}
                                variant="outlined"
                            />
                            {showDescriptionError && (
                                <Typography variant="body2">
                                    {errors.description[0]}
                                </Typography>
                            )}
                        </div>
                        {submitError && (
                            <Typography variant="body2">
                                {submitError}
                            </Typography>
                        )}
                        <Box
                            mt={2}
                            mb={2}
                            style={{
                                display: 'flex',
                                // flexDirection: 'column',
                                flex: 1
                            }}>
                            <TextField
                                style={{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Paste Series Image URL"
                                name="image_url"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'cat_image',
                                        event.target.value
                                    )
                                }
                                type="url"
                                value={values.cat_image}
                                variant="outlined"
                            />
                            <label
                                style={{
                                    backgroundColor: 'steelblue',
                                    color: 'white',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    margin: 'auto',
                                    height: '25px',
                                    padding: '3px'
                                }}>
                                <Typography style={{ color: '#FFF' }}>
                                    Select Image
                                </Typography>
                                <FileUploader
                                    hidden
                                    accept="image/*"
                                    storageRef={firebase.storage().ref('Photo')}
                                    onUploadStart={this.handlePhotoUploadStart}
                                    onUploadError={this.handlePhotoUploadError}
                                    onUploadSuccess={
                                        this.handlePhotoUploadSuccess
                                    }
                                    onProgress={this.handlePhotoUploadProgress}
                                />
                            </label>
                            {this.state.isUploading && (
                                <div
                                    className="upload-progressbar"
                                    style={{
                                        width: '90%'
                                    }}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={Math.round(
                                            this.state.imageUploadProgress,
                                            2
                                        )}
                                        color="secondary"
                                    />
                                </div>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this._addNewCat}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this._submitForm}
                            disabled={!isValid}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

class VODListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            catData: []
        };
    }

    componentDidMount = async () => {
        this.getData();
    };

    getData = async () => {
        firestore_db.collection('main_vod_categories').onSnapshot(getData => {
            this.setState({
                catData:
                    getData.size > 0
                        ? getData.docs.map(v => ({ id: v.id, ...v.data() }))
                        : []
            });
        });
    };

    _handleChange = async (event, newValue) => {
        console.log(newValue);
        this.setState({
            value: newValue
        });
    };

    setExpand = iDx => {
        this.setState({
            [`open_` + [iDx]]: !this.state[`open_` + [iDx]]
        });
    };
    render() {
        const { classes } = this.props;
        const { value, catData } = this.state;
        return (
            <DashboardLayout title="VOD Listing">
                <div className={classes.root2}>
                    <div
                        style={{
                            padding: '20px'
                        }}>
                        <AddNewVODCategory getData={this.getData} />
                        <Box mt={2}>
                            {catData.map((data, iDx) => {
                                console.log(data);
                                return (
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={
                                                'panel1bh-content' + iDx
                                            }
                                            id={'panel1bh-header_' + iDx}>
                                            <Avatar
                                                src={data.cat_image || null}
                                            />
                                            <Typography
                                                style={{
                                                    marginLeft: '20px',
                                                    marginTop: '8px'
                                                }}>
                                                {data.category_name}
                                            </Typography>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    textAlign: 'end'
                                                }}>
                                                <IconButton
                                                    size="small"
                                                    onClick={async () =>
                                                        window.confirm(
                                                            'Delete the item?'
                                                        )
                                                            ? [
                                                                  await firestore_db
                                                                      .doc(
                                                                          'main_vod_categories/' +
                                                                              data.id
                                                                      )
                                                                      .delete(),
                                                                  this.getData()
                                                              ]
                                                            : null
                                                    }>
                                                    <Delete />
                                                </IconButton>
                                            </div>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <VODSubCategory
                                                dataId={data.id}
                                                data={data}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                );
                            })}
                        </Box>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

const classes = makeStyles(theme => ({
    root2: {
        // flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        display: 'flex'
        // height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`
    }
}));

export default withStyles(classes)(VODListing);
