import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Delete } from '@material-ui/icons';
import { firestore_db } from '../../config/firebase_config';
import { Box, Typography, Grid, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import AddNewAd from './AddNewAd';

export default class AdListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adData: {
                left: [],
                right: []
            }
        };
    }

    componentDidMount = async () => {
        setTimeout(() => this.getData(), 1500);
    };

    getData = async () => {
        firestore_db.collection('app_ads').onSnapshot(getAds => {
            console.log(getAds);
            if (getAds.size > 0) {
                console.log(getAds);

                const { adData } = this.state;
                adData.left = [];
                adData.right = [];
                getAds.docs.map(v => {
                    if (v.data().type == 'ls') {
                        adData.left.push({ id: v.id, ...v.data() });
                    } else if (v.data().type == 'rs') {
                        adData.right.push({ id: v.id, ...v.data() });
                    }
                });
                this.setState({
                    ...adData
                });
            }
        });
    };

    deleteAd = async id => {
        if (window.confirm('Want to delete this Ad?')) {
            await firestore_db.doc('app_ads/' + id).delete();
        }
    };

    render() {
        const { adData } = this.state;
        console.log(adData);
        return (
            <Box mt={2}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={'panel1bh-content'}
                        id={'panel1bh-header_'}>
                        <Typography
                            style={{
                                marginLeft: '20px',
                                marginTop: '8px'
                            }}>
                            {'Left Side Ads'}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={2}>
                            {adData.left.map(vid => {
                                return (
                                    <Grid item xs={3}>
                                        <Card>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    alt={vid.title}
                                                    height="140"
                                                    image={vid.cat_image}
                                                    title={vid.title}
                                                />
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="h2">
                                                        {vid.title}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p">
                                                        {vid.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <IconButton
                                                    onClick={() =>
                                                        this.deleteAd(vid.id)
                                                    }>
                                                    <Delete />
                                                </IconButton>
                                                <AddNewAd
                                                    editData={vid}
                                                    edit={true}
                                                />
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={'panel1bh-content'}
                        id={'panel1bh-header_'}>
                        <Typography
                            style={{
                                marginLeft: '20px',
                                marginTop: '8px'
                            }}>
                            {'Right Side Ads'}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={2}>
                            {adData.right.map(vid => {
                                return (
                                    <Grid item xs={3}>
                                        <Card>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    alt={vid.title}
                                                    height="140"
                                                    image={vid.cat_image}
                                                    title={vid.title}
                                                />
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="h2">
                                                        {vid.title}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p">
                                                        {vid.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <IconButton
                                                    onClick={() =>
                                                        this.deleteAd(vid.id)
                                                    }>
                                                    <Delete />
                                                </IconButton>
                                                <AddNewAd
                                                    editData={vid}
                                                    edit={true}
                                                />
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* {adData.map((data, iDx) => {
                    console.log(data);
                    return (
                        <ExpansionPanel> */}
                {/* <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={
                                    'panel1bh-content' + iDx
                                }
                                id={'panel1bh-header_' + iDx}>
                                <Avatar
                                    src={data.cat_image || null}
                                />
                                <Typography
                                    style={{
                                        marginLeft: '20px',
                                        marginTop: '8px'
                                    }}>
                                    {data.category_name}
                                </Typography>
                                <div
                                    style={{
                                        flex: 1,
                                        textAlign: 'end'
                                    }}>
                                    <IconButton
                                        size="small"
                                        onClick={async () =>
                                            window.confirm(
                                                'Delete the item?'
                                            )
                                                ? [
                                                    await firestore_db
                                                        .doc(
                                                            'main_vod_categories/' +
                                                            data.id
                                                        )
                                                        .delete(),
                                                    this.getData()
                                                ]
                                                : null
                                        }>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <VODSubCategory
                                    dataId={data.id}
                                    data={data}
                                />
                            </ExpansionPanelDetails> */}
                {/* </ExpansionPanel>
                    );
                })} */}
            </Box>
        );
    }
}
