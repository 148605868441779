import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    TextField,
    CircularProgress
} from '@material-ui/core';
import _ from 'underscore';
import validate from 'validate.js';
import { firestore_db } from '../../config/firebase_config';

const schema = {
    name: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    description: {
        presence: { allowEmpty: false, message: 'is required' }
    }
};

class AddMainCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddDialog: false,
            values: {
                name: '',
                description: ''
            },
            touched: {
                name: false,
                description: false
            },
            errors: {
                name: null,
                description: null
            },
            isValid: false,
            isLoading: false,
            submitError: null
        }
    }

    validateForm = _.debounce(() => {
        const { values } = this.state;

        const newState = { ...this.state };
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = errors ? false : true;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };

        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    _addNewCat = async () => {
        const { showAddDialog } = this.state;
        this.setState({
            showAddDialog: !showAddDialog
        })
    }

    _submitForm = async() => {
        const { values } = this.state;
        console.log(values)
        let data = {
            category_name: values.name,
            description: values.description,
            create_date: Date.now()
        }
        await firestore_db.collection('main_categories').doc().set(data);
        this.props.getData();
        this._addNewCat()
    }

    render() {
        const {
            showAddDialog,
            values,
            touched,
            errors,
            isValid,
            submitError,
            isLoading
        } = this.state;

        const showNameError = touched.name && errors.name;
        const showDescriptionError = touched.description && errors.description;

        return (
            <>
                <Button variant="contained" color="primary" onClick={this._addNewCat}>
                    Add new Category
                </Button>
                <Dialog
                    open={showAddDialog}
                    fullWidth
                    maxWidth={'sm'}
                >
                    <DialogTitle>
                        <Typography component="h3" variant="h3">
                            Add New Main Category
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <TextField  
                                style = {{
                                    margin: '5px 10px'
                                }}
                                fullWidth
                                label="Category Name"
                                name="name"
                                onChange={event =>
                                    this.handleFieldChange('name', event.target.value)
                                }
                                type="text"
                                value={values.name}
                                variant="outlined"
                            />
                            {showNameError && (
                                <Typography
                                    variant="body2"
                                >
                                    {errors.name[0]}
                                </Typography>
                            )}
                            <TextField
                                style = {{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Description"
                                name="description"
                                rows={5}
                                multiline
                                onChange={event =>
                                    this.handleFieldChange('description', event.target.value)
                                }
                                type="text"
                                value={values.description}
                                variant="outlined"
                            />
                            {showDescriptionError && (
                                <Typography
                                    variant="body2"
                                >
                                    {errors.description[0]}
                                </Typography>
                            )}
                        </div>
                        {submitError && (
                            <Typography
                                variant="body2"
                            >
                                {submitError}
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this._addNewCat}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={this._submitForm} disabled={!isValid}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default AddMainCategory;