import React from 'react';
import { Dashboard as DashboardLayout } from 'layouts';
import { firestore_db } from '../../config/firebase_config';
import MaterialTable from 'material-table';
import { withStyles, Button, Checkbox } from '@material-ui/core';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import Payment from '@material-ui/icons/Payment';
// import Collapse from '@material-ui/Collapse';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import EditDialog from './EditDialog';
import NotesDialog from "../../common/add_show_notes_dialog";
import PaymentHistoryDialog from "../../common/show_payment_history";
// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 4
    },
});


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    JSON: forwardRef((props, ref) => <CloudCircleIcon {...props} ref={ref} />)
};

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editData: null,
            notesUserObj: null,
            paymentUserObj: null
        }
    }

    componentDidMount = async () => {
        this.getData();
    };

    getData = async () => {
        this.setState({
            data: []
        })
        let data = await firestore_db.collection('users').get();
        this.setState({
            data:
                data.size > 0
                    ? data.docs.map(v => ({
                        id: v.id,
                        date: v.data().register_date ? new Date(v.data().register_date).toISOString().substring(0, 10) : '',
                        ...v.data()
                    }))
                    : []
        });
    };

    changeStatus = async ({ id }, status) => {
        await firestore_db.doc(`users/${id}`).update({ status: (status == 1 ? true : false) })
        this.getData()
    }

    changePlan = async ({ id }, plan) => {
        await firestore_db.doc(`users/${id}`).update({ plan: plan })
        this.getData()
    }

    verifiedChanges = async (id, value) => {
        await firestore_db.doc(`users/${id}`).update({ verified: value })
        this.getData()
    }
    showNotes = (data) => {
        console.log('ShowNotes')
        this.setState({
            notesUserObj: { ...data, note_user_type: "user" }
        })

    }
    showPaymentsHistory = (data) => {
        console.log('showPaymentsHistory')
        this.setState({
            paymentUserObj: data
        })

    }
    editUser = (data) => {
        this.setState({
            editData: data
        })
    }

    onEditClose = (flag = false) => {
        if (flag) {
            this.getData()
        }
        this.setState({
            editData: null
        })
    }
    onNotesClose = () => {
        this.setState({
            notesUserObj: null
        })
    }
    onPaymentHistoryClose = () => {
        this.setState({
            paymentUserObj: null
        })
    }
    render() {
        const { classes } = this.props;
        const { data } = this.state
        return (
            <DashboardLayout title="Users Listing">
                <div className={classes.root}>
                    <MaterialTable
                        columns={[
                            { title: 'User Name', field: 'name' },
                            { title: 'Email', field: 'email' },
                            { title: 'Device Id', field: 'device_id' },
                            { title: 'Phone num.', field: 'phone_number' },
                            { title: 'Mac Adr.', field: 'macaddress' },
                            { title: 'Create Date', field: 'date' },
                            {
                                title: 'Notes', field: 'notes', render: (rowData) => <VisibilityIcon color="secondary" onClick={() => this.showNotes(rowData)} />
                            },

                            // {
                            //     title: 'Expire Days', field: 'plan_expire_days',
                            // },
                            // {
                            //     title: 'Payments',
                            //     tooltip: 'Show Payments',
                            //     render: rowData => <Payment color="secondary" onClick={() => this.showPaymentsHistory(rowData)} />,
                            // },
                            {
                                title: 'Status', field: 'status',
                                render: (rowData) => <select value={(rowData.status ? 1 : 0)} onChange={(e) => this.changeStatus(rowData, e.target.value)}><option value={1}>Active</option><option value={0}>In Active</option></select>
                            },
                            {
                                title: 'Plan', field: 'plan',
                                render: (rowData) =>
                                    <select value={rowData.plan} onChange={(e) => this.changePlan(rowData, e.target.value)}>
                                        <option value={'BASIC'}>Basic</option>
                                        <option value={'PREMIUM'}>Premium</option>
                                    </select>
                            },
                            { title: 'Verified', field: 'verified', render: (rowData) => <Checkbox defaultChecked={rowData.verified || false} size='small' onChange={(e) => this.verifiedChanges(rowData.id, e.target.checked)} /> },
                            { title: 'Reg. Code', field: 'code' },
                        ]}
                        data={data}
                        title=""
                        icons={tableIcons}
                        style={{
                            boxShadow: 'none !important'
                        }}

                        actions={[
                            {
                                icon: tableIcons.Delete,
                                tooltip: 'Delete',
                                onClick: async (event, rowData) =>
                                    window.confirm('You want to delete?')
                                        ? await firestore_db
                                            .doc('users/' + rowData.id)
                                            .delete()
                                        : null
                            },
                            {
                                icon: tableIcons.Edit,
                                tooltip: 'Edit User',
                                onClick: async (event, rowData) => {
                                    this.editUser(rowData);
                                }
                            }
                        ]}
                        options={{
                            actionsColumnIndex: -1
                        }}

                    />
                </div>
                {this.state.editData && <EditDialog data={this.state.editData} onClose={this.onEditClose} />}
                {this.state.notesUserObj && <NotesDialog userData={this.state.notesUserObj} onClose={this.onNotesClose} />}
                {this.state.paymentUserObj && <PaymentHistoryDialog userData={this.state.paymentUserObj} onClose={this.onPaymentHistoryClose} />}
                
            </DashboardLayout>
        )
    }
}

export default withStyles(styles)(Users);