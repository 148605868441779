import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    TextField,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Box,
    Divider,
    LinearProgress,
    FormControl,
    Input,
    Select,
    InputLabel,
    MenuItem,
    Checkbox,
    ListItemText
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'underscore';
import validate from 'validate.js';
import { firestore_db } from '../../config/firebase_config';
import axios from 'axios';
import FileUploader from 'react-firebase-file-uploader';
import firebase from 'firebase';
import BunnyComponent from 'views/BunnyComponent';
import { Edit } from '@material-ui/icons';

const schema = {
    name: {
        presence: { allowEmpty: false, message: 'is required' }
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

class AddNewVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddDialog: false,
            values: {
                name: '',
                description: '',
                duration: 0,
                video_url: '',
                video_img_url: '',
                additional_url:"",
                cat_id: [],
                actor: '',
                director: '',
                writer: '',
                year: ''
            },
            touched: {
                name: false,
                description: false
            },
            errors: {
                name: null,
                description: null
            },
            isValid: false,
            isLoading: false,
            submitError: null,
            categories: []
        };
        this.handlePhotoUploadStart = this.handlePhotoUploadStart.bind(this);
        this.handlePhotoUploadProgress = this.handlePhotoUploadProgress.bind(
            this
        );
        this.handlePhotoUploadError = this.handlePhotoUploadError.bind(this);
        this.handlePhotoUploadSuccess = this.handlePhotoUploadSuccess.bind(
            this
        );
        this._getBunnySelected = this._getBunnySelected.bind(this);
    }

    componentDidMount = async () => {
        // let cat = await firestore_db.collection('categories').get();
        // this.setState({
        //     categories: (cat.size > 0 ? cat.docs.map((c) => ({id: c.id, ...c.data()})) : [])
        // })
        const { edit, editData } = this.props;
        if (edit) {
            this.setState({
                values: {
                    name: editData.title,
                    description: editData.description,
                    duration: editData.duration,
                    video_url: editData.video_url,
                    video_img_url: editData.video_img_url,
                    additional_url:editData.additional_url? editData.additional_url : '',
                    cat_id: editData.categories,
                    actor: editData.actor ? editData.actor : '',
                    director: editData.director ? editData.director : '',
                    writer: editData.writer ? editData.writer : '',
                    year: editData.year ? editData.year : '',
                    create_date: editData.create_date
                }
            });
        }
    };

    validateForm = _.debounce(() => {
        const { values } = this.state;

        const newState = { ...this.state };
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = errors ? false : true;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };

        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    _addNewCat = async () => {
        const { showAddDialog } = this.state;
        if (showAddDialog && !this.props.edit) {
            this.setState({
                showAddDialog: false,
                values: {
                    name: '',
                    description: '',
                    duration: 0,
                    video_url: '',
                    video_img_url: '',
                    additional_url:'',
                    cat_id: [],
                    actor: '',
                    director: '',
                    writer: '',
                    year: ''
                },
                touched: {
                    name: false,
                    description: false
                },
                errors: {
                    name: null,
                    description: null
                },
                isValid: false,
                isLoading: false,
                submitError: null,
                categories: []
            });
        }
        this.setState({
            showAddDialog: !showAddDialog
        });
    };

    _submitForm = async () => {
        const { values, categories } = this.state;
        const { data } = this.props;

        if (this.props.edit) {
            data.subData[this.props.parendiDx].subData2[this.props.iDx].videos[
                this.props.editVidIdx
            ] = {
                title: values.name,
                description: values.description || '',
                video_url: values.video_url,
                video_img_url: values.video_img_url,
                additional_url:values.additional_url,
                duration: Number(values.duration),
                writer: values.writer,
                director: values.director,
                actor: values.actor,
                year: values.year,
                create_date: this.props.edit ? values.create_date : Date.now()
            };
        } else {
            data.subData[this.props.parendiDx].subData2[
                this.props.iDx
            ].videos.push({
                title: values.name,
                description: values.description || '',
                video_url: values.video_url,
                video_img_url: values.video_img_url,
                additional_url:values.additional_url,
                duration: Number(values.duration),
                writer: values.writer,
                director: values.director,
                actor: values.actor,
                year: values.year,
                create_date: this.props.edit ? values.create_date : Date.now()
            });
        }
        console.log(data);

        setTimeout(() => {
            firestore_db
                .doc('main_vod_categories/' + this.props.dataId)
                .update(data)
                .then(() => this._addNewCat());
            console.log('added data');
        }, 800);

        // window.location.reload();
    };

    _handleCatChange = async e => {
        const { values } = this.state;
        values.cat_id = e.target.value;
        this.setState({ ...values });
    };

    callBack = async (image, video, duration) => {
        const { values } = this.state;
        values.video_img_url = image;
        values.video_url = video;
        values.duration = duration;
        this.setState({ ...values });
    };

    handlePhotoUploadStart() {
        this.setState({ isUploading: true, progress: 0 });
    }
    handlePhotoUploadProgress(progress) {
        this.setState({ imageUploadProgress: progress });
    }
    handlePhotoUploadError(error) {
        this.setState({ isUploading: false });
    }
    handlePhotoUploadSuccess(filename) {
        this.setState({
            filename: filename,
            progress: 100,
            isUploading: false
        });
        firebase
            .storage()
            .ref('Photo')
            .child(filename)
            .getDownloadURL()
            .then(url => this.handleFieldChange('video_img_url', url));
    }

    _getBunnySelected = async video => {
        console.log(video);
        let url = `https://trivisiontv.b-cdn.net/Video/${
            video.Path.split('/').reverse()[1]
        }/${video.ObjectName}`;
        this.handleFieldChange('video_url', url);
    };

    render() {
        const {
            showAddDialog,
            values,
            touched,
            errors,
            isValid,
            submitError,
            categories
        } = this.state;

        const showNameError = touched.name && errors.name;
        const showDescriptionError = touched.description && errors.description;

        return (
            <>
                {this.props.edit ? (
                    <IconButton>
                        <Edit onClick={this._addNewCat} />
                    </IconButton>
                ) : (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={this._addNewCat}>
                        Add new Episode
                    </Button>
                )}
                <Dialog
                    open={showAddDialog}
                    // fullWidth
                    // maxWidth={'sm'}
                    fullScreen
                    TransitionComponent={Transition}>
                    <AppBar color="inherit" elevation={0}>
                        <Toolbar>
                            <Typography variant="h2">Video</Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={this._addNewCat}
                                aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{ marginTop: '100px' }}>
                        <div>
                            <div>
                                {/* <Box
                                    style={{
                                        margin: '10px 10px',
                                        textAlign: 'center'
                                    }}>
                                    <VideoUpload
                                        callBack={this.callBack.bind(this)}
                                    />
                                </Box>
                                <Typography
                                    variant="h4"
                                    style={{
                                        margin: '20px 10px',
                                        textAlign: 'center'
                                    }}>
                                    OR
                                </Typography> */}
                                <BunnyComponent
                                    getBunnySelected={this._getBunnySelected}
                                />
                                <Box>
                                    <TextField
                                        style={{
                                            margin: '10px 10px 20px 10px'
                                        }}
                                        fullWidth
                                        label="Paste Video URL"
                                        name="url"
                                        onChange={event =>
                                            this.handleFieldChange(
                                                'video_url',
                                                event.target.value
                                            )
                                        }
                                        type="text"
                                        value={values.video_url}
                                        variant="outlined"
                                    />
                                    <TextField
                                        style={{
                                            margin: '10px 10px 20px 10px'
                                        }}
                                        fullWidth
                                        label="Paste Image URL"
                                        name="url"
                                        onChange={event =>
                                            this.handleFieldChange(
                                                'video_img_url',
                                                event.target.value
                                            )
                                        }
                                        type="text"
                                        value={values.video_img_url}
                                        variant="outlined"
                                    />
                                     <TextField
                                        style={{
                                            margin: '10px 10px 20px 10px'
                                        }}
                                        fullWidth
                                        label="Additional URL"
                                        name="url"
                                        onChange={event =>
                                            this.handleFieldChange(
                                                'additional_url',
                                                event.target.value
                                            )
                                        }
                                        type="text"
                                        value={values.additional_url}
                                        variant="outlined"
                                    />
                                    <div
                                        style={{
                                            background: '#4682b3',
                                            borderRadius: '10px',
                                            textAlign: 'center',
                                            marginBottom: '20px',
                                            marginRight: '-10px',
                                            width: 'inherit'
                                        }}>
                                        <label
                                            style={{
                                                color: 'white',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                margin: 'auto',
                                                height: '25px',
                                                padding: '3px'
                                            }}>
                                            <Typography
                                                style={{ color: '#FFF' }}>
                                                Select Image
                                            </Typography>
                                            <FileUploader
                                                hidden
                                                accept="image/*"
                                                randomizeFilename
                                                storageRef={firebase
                                                    .storage()
                                                    .ref('Photo')}
                                                onUploadStart={
                                                    this.handlePhotoUploadStart
                                                }
                                                onUploadError={
                                                    this.handlePhotoUploadError
                                                }
                                                onUploadSuccess={
                                                    this
                                                        .handlePhotoUploadSuccess
                                                }
                                                onProgress={
                                                    this
                                                        .handlePhotoUploadProgress
                                                }
                                            />
                                        </label>
                                    </div>
                                    {this.state.isUploading && (
                                        <div
                                            className="upload-progressbar"
                                            style={{
                                                width: '100%',
                                                margin: '10px 0 10px 0'
                                            }}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={Math.round(
                                                    this.state
                                                        .imageUploadProgress,
                                                    2
                                                )}
                                                color="secondary"
                                            />
                                        </div>
                                    )}
                                    <TextField
                                        style={{
                                            margin: '10px 10px'
                                        }}
                                        fullWidth
                                        label="Duration in Seconds"
                                        name="duration"
                                        onChange={event =>
                                            this.handleFieldChange(
                                                'duration',
                                                event.target.value
                                            )
                                        }
                                        type="number"
                                        value={values.duration}
                                        variant="outlined"
                                    />
                                </Box>
                            </div>
                            <Divider orientation="horizontal" />
                            <TextField
                                style={{
                                    margin: '20px 10px'
                                }}
                                fullWidth
                                label="Title"
                                name="name"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'name',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.name}
                                variant="outlined"
                            />
                            {showNameError && (
                                <Typography variant="body2">
                                    {errors.name[0]}
                                </Typography>
                            )}
                            {/* <FormControl variant="outlined" fullWidth style={{
                                margin: '10px 10px'
                            }}>
                                <InputLabel id="demo-mutiple-checkbox-label">Select Category</InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={values.cat_id}
                                    onChange={this._handleCatChange}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(', ')}
                                    variant="outlined"
                                    MenuProps={MenuProps}
                                >
                                    {categories.map((name) => (
                                        <MenuItem key={name.id} value={name.category_name}>
                                            <Checkbox checked={values.cat_id.indexOf(name.category_name) > -1} />
                                            <ListItemText primary={name.category_name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                            <TextField
                                style={{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Description"
                                name="description"
                                rows={5}
                                multiline
                                onChange={event =>
                                    this.handleFieldChange(
                                        'description',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.description}
                                variant="outlined"
                            />
                            {showDescriptionError && (
                                <Typography variant="body2">
                                    {errors.description[0]}
                                </Typography>
                            )}
                            <TextField
                                style={{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Year"
                                name="year"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'year',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.year}
                                variant="outlined"
                            />
                            <TextField
                                style={{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Actor"
                                name="actor"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'actor',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.actor}
                                variant="outlined"
                            />
                            <TextField
                                style={{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Director"
                                name="director"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'director',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.director}
                                variant="outlined"
                            />
                            <TextField
                                style={{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Writer"
                                name="writer"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'writer',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.writer}
                                variant="outlined"
                            />
                        </div>
                        {submitError && (
                            <Typography variant="body2">
                                {submitError}
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this._addNewCat}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this._submitForm}
                            disabled={!isValid}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default AddNewVideo;
