import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Button } from '@material-ui/core';

// Material components
import { Grid, Typography } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import FileUploader from 'react-firebase-file-uploader';
import { firestore_db } from '../../config/firebase_config';

import firebase from 'firebase';
import { TextField, LinearProgress } from '@material-ui/core';

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 4
    },
    content: {
        // marginTop: '150px',
        // textAlign: 'right',
        padding: '15px',
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
            border: '1px solid #dfe3e8',
            borderBottom: 'none'
        }
    }
});

class Apks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apk_url: '',
            version: ''
        };

        this.handlePhotoUploadStart = this.handlePhotoUploadStart.bind(this);
        this.handlePhotoUploadProgress = this.handlePhotoUploadProgress.bind(
            this
        );
        this.handlePhotoUploadError = this.handlePhotoUploadError.bind(this);
        this.handlePhotoUploadSuccess = this.handlePhotoUploadSuccess.bind(
            this
        );
    }

    handlePhotoUploadStart() {
        this.setState({ isUploading: true, progress: 0 });
    }
    handlePhotoUploadProgress(progress) {
        this.setState({ imageUploadProgress: progress });
    }
    handlePhotoUploadError(error) {
        this.setState({ isUploading: false });
    }
    handlePhotoUploadSuccess(filename) {
        const { version } = this.state;
        this.setState({
            filename: filename,
            progress: 100,
            isUploading: false
        });
        firebase
            .storage()
            .ref('Apks')
            .child('Apk.apk')
            .getDownloadURL()
            .then(url => {
                this.setState({ apk_url: url });
            });
    }

    onSubmit = async () => {
        await firestore_db
            .collection('apks')
            .doc('apk')
            .set({
                apk_url: this.state.apk_url,
                version: this.state.version
            });
        alert(
            `Apk uploaded with version ${this.state.version}, you need to build from Ads section`
        );
        this.setState({
            apk_url: '',
            version: ''
        });
    };
    render() {
        const { classes } = this.props;

        return (
            <DashboardLayout title="Apks">
                <Grid justify="center" spacing={4} className={classes.content}>
                    <TextField
                        style={{
                            margin: '10px 10px 20px 10px'
                        }}
                        fullWidth
                        label="Version"
                        name="version"
                        type="text"
                        value={this.state.version}
                        variant="outlined"
                        onChange={event =>
                            this.setState({ version: event.target.value })
                        }
                    />
                    <TextField
                        style={{
                            margin: '10px 10px 20px 10px'
                        }}
                        fullWidth
                        label="APK URL"
                        name="url"
                        type="text"
                        value={this.state.apk_url}
                        variant="outlined"
                        disabled
                    />
                    <label
                        style={{
                            color: 'white',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            margin: 'auto',
                            height: '25px',
                            padding: '3px'
                        }}>
                        <Typography
                            style={{
                                color: 'rgb(255 255 255)',
                                padding: '10px',
                                background: '#0000ff6b',
                                width: 'fit-content',
                                borderRadius: '10px'
                            }}>
                            Select Apk
                        </Typography>
                        <FileUploader
                            hidden
                            accept="*"
                            filename="Apk.apk"
                            storageRef={firebase.storage().ref('Apks')}
                            onUploadStart={this.handlePhotoUploadStart}
                            onUploadError={this.handlePhotoUploadError}
                            onUploadSuccess={this.handlePhotoUploadSuccess}
                            onProgress={this.handlePhotoUploadProgress}
                        />
                    </label>
                    {this.state.isUploading && (
                        <div
                            className="upload-progressbar"
                            style={{
                                width: '100%',
                                margin: '10px 0 10px 0'
                            }}>
                            <LinearProgress
                                variant="determinate"
                                value={Math.round(
                                    this.state.imageUploadProgress,
                                    2
                                )}
                                color="secondary"
                            />
                        </div>
                    )}
                    <Button
                        style={{
                            float: 'right'
                        }}
                        onClick={this.onSubmit}
                        variant="contained"
                        color="secondary">
                        Submit
                    </Button>
                </Grid>
            </DashboardLayout>
        );
    }
}

export default withStyles(styles)(Apks);
