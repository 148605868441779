import * as firebase from 'firebase';

const config = {
    // apiKey: "AIzaSyBiY49xosLBOvJQ4qfjC6ZoRkblTJGZvCI",
    // authDomain: "test-project-97086.firebaseapp.com",
    // databaseURL: "https://test-project-97086.firebaseio.com",
    // projectId: "test-project-97086",
    // storageBucket: "test-project-97086.appspot.com",
    // messagingSenderId: "866445419076",
    // appId: "1:866445419076:web:d23c5d8989abc6104e9211",
    // measurementId: "G-8DVFXM5PRJ"

    apiKey: 'AIzaSyA0ZZ0GGvME0VCtK-L65HdOHOzPwPVSqPE',
    authDomain: 'tv-trivision.firebaseapp.com',
    databaseURL: 'https://tv-trivision.firebaseio.com',
    projectId: 'tv-trivision',
    storageBucket: 'tv-trivision.appspot.com',
    messagingSenderId: '1008981301447',
    appId: '1:1008981301447:web:b3d6d16c23c3ba705006e2',
    measurementId: 'G-EPX5TH7QSK'
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

var auth = firebase.auth();
var firestore_db = firebase.firestore();
var storage = firebase.storage();

firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
    } else {
        if (window.location.href.split('/')[3] !== 'signin') {
            window.location.href = '/signin';
        }
    }
});

const cloudUrl = `http://localhost:5000/tv-trivision/us-central1`;

export { auth, firestore_db, storage, cloudUrl };