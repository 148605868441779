import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@material-ui/core';
import { firestore_db } from '../config/firebase_config';
import { useState, useEffect, useRef } from 'react'
import React from 'react';
import ContentLoader from 'react-content-loader'

import './App.css';
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as Addicon } from '../icons/add.svg'
import { range } from 'underscore';
import NotesItem from './notes_iem';
import { async } from 'validate.js';
const NotesDialog = ({ onClose, userData }) => {

    const renderAfterCalled = useRef(false);
    const [addNotes, setNotesData] = useState("")
    const [notesList, setNotesList] = useState([])
    const [processing, setProcessing] = useState(false);
    useEffect(() => {
        if (!renderAfterCalled.current) {
            fetchData();
        }
    })
    async function fetchData() {
        if (userData['note_user_type'] == 'admin') {
            let data = await firestore_db.collection('notes').where("note_user_type", "==", 'admin').orderBy('create_time', 'desc').get();
            var notesListObj = data.docs.map(v => ({
                id: v.id,
                ...v.data()
            }));
            renderAfterCalled.current = true;
            setNotesList(notesListObj)
        } else {
            let data = await firestore_db.collection('notes').where("user_id", "==", userData['id']).orderBy('create_time', 'desc').get();
            var notesListObj = data.docs.map(v => ({
                id: v.id,
                ...v.data()
            }));
            renderAfterCalled.current = true;
            setNotesList(notesListObj)
        }

    }

    const saveData = async () => {
        if (addNotes.trim().toString() != null && addNotes.trim().toString() != "") {
            setProcessing(true);
            const date = new Date();
            const payload = {
                notes: addNotes,
                create_time: date.getTime(),
                user_id: userData['id'],
                note_user_type: userData['note_user_type']

            }
            await firestore_db.collection('notes').add(payload)
            setProcessing(false);
            setNotesData("")
            fetchData();
        }
    }
    const onDeleteNotes = async () => { 
        renderAfterCalled.current = false;
        fetchData();
    }
    
    return (
        <div>
            <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
                <DialogTitle>Notes</DialogTitle>
                <DialogContent style={{ height: '600px' }}>
                    <TextField
                        onChange={(e) => setNotesData(e.target.value)}
                        variant="outlined"
                        fullWidth
                        value={addNotes}
                        placeholder="Add Notes"
                        style={{ marginBottom: 10 }}
                    />
                    <div>
                        {!renderAfterCalled.current ? range(1, 10).map((index) => { return (<ContentLoader key={index} />) }) : notesList.length > 0 ? notesList.map((note, index) => {
                            return (
                                <div className='note-preview' key={index} >
                                    <NotesItem key={note.id} note={note} onDelete={onDeleteNotes} />
                                </div>
                            )
                        }) : <div className='center'>No Notes avaliable</div>}
                    </div>
                    <div className='floating-button' onClick={saveData} disabled={processing}>
                        {processing ? <CircularProgress style={{ 'color': 'white' }} /> : <Addicon />}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default NotesDialog;