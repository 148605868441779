import React from 'react';
import AddNewAd from './AddNewAd';

export default class AddNewAdButtons extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <AddNewAd slug={'ls'} />
                <AddNewAd slug={'rs'} />
            </>
        );
    }
}
