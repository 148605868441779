import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import React from 'react';
import { useState } from 'react';

const NameEdit = ({defaultValue, onSave}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue || '');

  return(
    <>
      <IconButton onClick={() => setOpen(true)} size='small'>
        <Edit />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(true)} fullWidth maxWidth="md">
      <DialogContent>
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          variant="outlined"
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant='contained' onClick={() => {onSave(value); setOpen(false);}}>
          Save
        </Button>
        <Button onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
    </>    
  )
}

export default NameEdit;