import React from 'react';
import AddNewVideo from './AddNewVideo';
import { Box, Grid, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Delete } from '@material-ui/icons';
import { firestore_db } from '../../config/firebase_config';

class VideoListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flag: false,
            videoData: false
        };
    }

    deleteVideo = async id => {
        const { data } = this.props;
        if (window.confirm('Want to delete this video?')) {
            // data.subData[this.props.parendiDx].subData2[
            //     this.props.iDx
            // ].videos.splice(iDx, 1);
            await firestore_db.doc('vod_video/' + id).delete();
            this._getVideoData();
            // window.location.reload();
        }
    };

    _getVideoData = async () => {
        let getData = await firestore_db
            .collection('vod_video')
            .where(
                'series_id',
                '==',
                firestore_db.doc(`vod_series/${this.props.dataId}`)
            )
            .orderBy('create_date', 'desc')
            .get();
        // getData.docs.map(vodVid => {
        //     let newDoamin = 'https://trivision-media.b-cdn.net';
        //     try {
        //         let url = vodVid.data().video_url;

        //         if (url !== '') {
        //             let domain = new URL(url);
        //             const pathname = domain.pathname;
        //             if (domain.hostname === 'trivisiontv.b-cdn.net') {
        //                 vodVid.ref.update({
        //                     video_url_old: url,
        //                     video_url: `${newDoamin}${pathname}`
        //                 });
        //             }
        //         }
        //     } catch (e) {}
        // });
        this.setState({
            videoData:
                getData.size > 0
                    ? getData.docs.map(v => ({ id: v.id, ...v.data() }))
                    : [],
            flag: true
        });
    };

    render() {
        const { document } = this.props;
        const { flag, videoData } = this.state;
        return (
            <Box>
                {flag && (
                    <AddNewVideo
                        {...this.props}
                        getVideoData={this._getVideoData}
                        edit={false}
                    />
                )}
                <Box mt={2}>
                    {!flag && (
                        <Button onClick={this._getVideoData}>
                            Show Videos
                        </Button>
                    )}
                    {flag && (
                        <Grid container spacing={2}>
                            {videoData.map((vid, iDx) => {
                                return (
                                    <Grid item xs={4}>
                                        <Card
                                            style={{
                                                minWidth: '150px'
                                            }}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    alt={vid.title}
                                                    height="80"
                                                    image={vid.video_img_url}
                                                    title={vid.title}
                                                />
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="h2">
                                                        {vid.title}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p">
                                                        {vid.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <IconButton
                                                    onClick={() =>
                                                        this.deleteVideo(vid.id)
                                                    }>
                                                    <Delete />
                                                </IconButton>
                                                <AddNewVideo
                                                    edit={true}
                                                    copy={false}
                                                    editData={vid}
                                                    {...this.props}
                                                    editVidIdx={iDx}
                                                    vidId={vid.id}
                                                    getVideoData={
                                                        this._getVideoData
                                                    }
                                                />
                                                <AddNewVideo
                                                    edit={false}
                                                    copy={true}
                                                    editData={vid}
                                                    {...this.props}
                                                    editVidIdx={iDx}
                                                    vidId={vid.id}
                                                    getVideoData={
                                                        this._getVideoData
                                                    }
                                                />
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                </Box>
            </Box>
        );
    }
}

export default VideoListing;
