import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VODSeries from './VODSeries';
import { firestore_db } from '../../config/firebase_config';
import { Delete, Flag } from '@material-ui/icons';
import {
    Typography,
    IconButton,
    Button,
    TextField,
    Box
} from '@material-ui/core';
import NameEdit from 'common/NameEdit';
// import VODSubOfSubCategory from './VODSubOfSubCategory';

const INITIAL_STATE = {
    sub_cat_name: '',
    flag: false,
    subData: []
    // subData2: []
};
class VODSubCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showInput: false,
            values: { ...INITIAL_STATE }
        };
    }

    _addnewSubCat = async () => {
        if (this.state.showInput) {
            this.setState({
                values: { ...INITIAL_STATE }
            });
        }
        this.setState({
            showInput: !this.state.showInput
        });
    };

    _createNewSubCat = async () => {
        const { values } = this.state;
        let udata = {
            ...values,
            main_cat_id: firestore_db.doc(
                `vod_main_categories/${this.props.dataId}`
            )
        };
        await firestore_db
            .collection('vod_sub_categories')
            .doc()
            .set(udata);
        // window.location.reload();
        this._getSubData();
        this._addnewSubCat();

        // if (values.sub_cat_name.trim() !== '') {
        //     let udata = { ...this.props.data };
        //     udata.subData.push(values);
        //     await firestore_db
        //         .doc('main_vod_categories/' + this.props.dataId)
        //         .update(udata);
        //     // window.location.reload();
        //     this._addnewSubCat();
        // }
    };

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };
        newState.values[field] = value;
        this.setState({ ...newState });
    };

    deleteSubCat = async (id, iDx) => {
        const { data } = this.props;

        if (window.confirm('Delete the item?')) {
            // data.subData.splice(iDx, 1);
            await firestore_db.doc('vod_sub_categories/' + id).delete();
            // window.location.reload();
            this._getSubData();
            this._addnewSubCat();
        }
    };

    _getSubData = async () => {
        console.log(this.props.dataId);
        let getData = await firestore_db
            .collection('vod_sub_categories')
            .where(
                'main_cat_id',
                '==',
                firestore_db.doc(`vod_main_categories/${this.props.dataId}`)
            )
            .get();

        this.setState(
            {
                subData:
                    getData.size > 0
                        ? getData.docs.map(v => ({ id: v.id, ...v.data() }))
                        : [],
                flag: true
            },
            () => console.log(this.state.subData)
        );
    };

    changeName = async(id, value) => {
        await firestore_db
            .doc(`vod_sub_categories/${id}`)
            .update({sub_cat_name : value});
            this._getSubData()
    }
    render() {
        const { dataId, data } = this.props;
        const { values, showInput, flag, subData } = this.state;
        const isValid = values.sub_cat_name !== '';

        return (
            <div
                style={{
                    flex: 1
                }}>
                <Button
                    onClick={() => this._addnewSubCat()}
                    variant="outlined"
                    color="primary"
                    style={{ marginBottom: showInput ? 0 : 20 }}>
                    Add New Sub-Category
                </Button>
                {!flag && (
                    <Button
                        onClick={this._getSubData}
                        style={{ marginBottom: showInput ? 0 : 20 }}>
                        Show Sub Categories
                    </Button>
                )}
                {showInput && (
                    <Box
                        mt={2}
                        mb={2}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flex: 1
                        }}>
                        <TextField
                            style={{
                                margin: '10px 10px'
                            }}
                            fullWidth
                            label="Sub-Category Name"
                            name="name"
                            onChange={event =>
                                this.handleFieldChange(
                                    'sub_cat_name',
                                    event.target.value
                                )
                            }
                            type="text"
                            value={values.sub_cat_name}
                            variant="outlined"
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            disabled={!isValid}
                            onClick={this._createNewSubCat}
                            style={{ height: 40, margin: 'auto' }}>
                            Submit
                        </Button>
                    </Box>
                )}

                {flag &&
                    subData.map((doc, iDx) => {
                        return (
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={'panel1bh-content-sub' + iDx}
                                    id={'panel1bh-header_sub' + iDx}>
                                    <Typography>{doc.sub_cat_name}</Typography>
                                    <div
                                        style={{
                                            flex: 1,
                                            textAlign: 'end'
                                        }}>
                                        <NameEdit defaultValue={doc.sub_cat_name} onSave={(v) => this.changeName(doc.id, v)}/>
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                this.deleteSubCat(doc.id, iDx)
                                            }>
                                            <Delete />
                                        </IconButton>
                                        
                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <VODSeries dataId={doc.id} />
                                    {/* <VODSubOfSubCategory
                                        dataId={data.id}
                                        data={doc}
                                        iDx={iDx}
                                        fullData={data}
                                    /> */}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    })}
                {/* {dataId} */}
            </div>
        );
    }
}

export default VODSubCategory;
