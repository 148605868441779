import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { firestore_db, storage } from '../../config/firebase_config';
import { Delete } from '@material-ui/icons';
import {
    Typography,
    IconButton,
    Button,
    TextField,
    Box,
    LinearProgress,
    Avatar
} from '@material-ui/core';
import FileUploader from 'react-firebase-file-uploader';
import VideoListing from './VideoListing';
import firebase from 'firebase';

const INITIAL_STATE = {
    sub_cat_name: '',
    sub_cat_image_url: '',
    videos: [],
    imageUploadProgress: 0,
    isUploading: false
};
class VODSubOfSubCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showInput: false,
            values: { ...INITIAL_STATE }
        };

        this.handlePhotoUploadStart = this.handlePhotoUploadStart.bind(this);
        this.handlePhotoUploadProgress = this.handlePhotoUploadProgress.bind(
            this
        );
        this.handlePhotoUploadError = this.handlePhotoUploadError.bind(this);
        this.handlePhotoUploadSuccess = this.handlePhotoUploadSuccess.bind(
            this
        );
    }

    _addnewSubCat = async () => {
        if (this.state.showInput) {
            this.setState({
                values: { ...INITIAL_STATE }
            });
        }
        this.setState({
            showInput: !this.state.showInput
        });
    };

    _createNewSubCat = async () => {
        const { values } = this.state;
        const { fullData } = this.props;

        if (values.sub_cat_name.trim() !== '') {
            let udata = { ...this.props.fullData };
            fullData.subData[this.props.iDx].subData2.push(values);
            await firestore_db
                .doc('main_vod_categories/' + this.props.dataId)
                .update(udata);
            // window.location.reload();
            this._addnewSubCat();
        }
    };

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };
        newState.values[field] = value;
        this.setState({ ...newState });
    };

    deleteSubCat = async (cat, iDx) => {
        const { fullData } = this.props;

        if (window.confirm('Delete the item?')) {
            fullData.subData[this.props.iDx].subData2.splice(iDx, 1);
            await this.delay(2000);
            console.log(fullData);
            await firestore_db
                .doc('main_vod_categories/' + this.props.dataId)
                .update(fullData);
            // window.location.reload();
            this._addnewSubCat();
        }
    };

    delay = async time => {
        return new Promise(resolve => {
            setTimeout(() => resolve(), time);
        });
    };

    handlePhotoUploadStart() {
        this.setState({ isUploading: true, progress: 0 });
    }
    handlePhotoUploadProgress(progress) {
        this.setState({ imageUploadProgress: progress });
    }
    handlePhotoUploadError(error) {
        this.setState({ isUploading: false });
    }
    handlePhotoUploadSuccess(filename) {
        this.setState({
            filename: filename,
            progress: 100,
            isUploading: false
        });
        firebase
            .storage()
            .ref('Photo')
            .child(filename)
            .getDownloadURL()
            .then(url => this.handleFieldChange('sub_cat_image_url', url));
    }

    render() {
        const { dataId, data, fullData } = this.props;
        const { values, showInput } = this.state;
        const isValid =
            values.sub_cat_name !== '' && values.sub_cat_image_url !== '';

        return (
            <div
                style={{
                    flex: 1
                }}>
                <Button
                    onClick={() => this._addnewSubCat()}
                    variant="outlined"
                    color="primary"
                    style={{ marginBottom: showInput ? 0 : 20 }}>
                    Add New Series
                </Button>
                {showInput && (
                    <Box
                        mt={2}
                        mb={2}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flex: 1
                        }}>
                        <TextField
                            style={{
                                margin: '10px 10px'
                            }}
                            fullWidth
                            label="Series Name"
                            name="name"
                            onChange={event =>
                                this.handleFieldChange(
                                    'sub_cat_name',
                                    event.target.value
                                )
                            }
                            type="text"
                            value={values.sub_cat_name}
                            variant="outlined"
                        />
                        <TextField
                            style={{
                                margin: '10px 10px'
                            }}
                            fullWidth
                            label="Paste Series Image URL"
                            name="image_url"
                            onChange={event =>
                                this.handleFieldChange(
                                    'sub_cat_image_url',
                                    event.target.value
                                )
                            }
                            type="url"
                            value={values.sub_cat_image_url}
                            variant="outlined"
                        />
                        <br />
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={this._createNewSubCat}
                            disabled={!isValid}
                            style={{ height: 40, margin: 'auto' }}>
                            Submit
                        </Button>
                    </Box>
                )}
                {showInput && (
                    <Box
                        mt={2}
                        mb={2}
                        style={{
                            display: 'flex',
                            // flexDirection: 'column',
                            flex: 1
                        }}>
                        <label
                            style={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                margin: 'auto',
                                height: '25px',
                                padding: '3px'
                            }}>
                            <Typography style={{ color: '#FFF' }}>
                                Select Image
                            </Typography>
                            <FileUploader
                                hidden
                                accept="image/*"
                                storageRef={firebase.storage().ref('Photo')}
                                onUploadStart={this.handlePhotoUploadStart}
                                onUploadError={this.handlePhotoUploadError}
                                onUploadSuccess={this.handlePhotoUploadSuccess}
                                onProgress={this.handlePhotoUploadProgress}
                            />
                        </label>
                        {this.state.isUploading && (
                            <div
                                className="upload-progressbar"
                                style={{
                                    width: '90%'
                                }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={Math.round(
                                        this.state.imageUploadProgress,
                                        2
                                    )}
                                    color="secondary"
                                />
                            </div>
                        )}
                    </Box>
                )}
                {data.subData2.map((doc, iDx) => {
                    return (
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={'panel1bh-content-sub' + iDx}
                                id={'panel1bh-header_sub' + iDx}>
                                {doc.sub_cat_image_url ? (
                                    <Avatar src={doc.sub_cat_image_url} />
                                ) : null}
                                <Typography
                                    style={{
                                        marginLeft: '20px',
                                        marginTop: '8px'
                                    }}>
                                    {doc.sub_cat_name}
                                </Typography>
                                <div
                                    style={{
                                        flex: 1,
                                        textAlign: 'end'
                                    }}>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            this.deleteSubCat(doc, iDx)
                                        }>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {/* <VODSubOfSubCategory dataId={data.id} data={data}/> */}
                                <VideoListing
                                    document={doc}
                                    data={fullData}
                                    iDx={iDx}
                                    dataId={dataId}
                                    parendiDx={this.props.iDx}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    );
                })}
                {/* {dataId} */}
            </div>
        );
    }
}

export default VODSubOfSubCategory;
