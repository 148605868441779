import React from 'react';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from 'material-table';
import { firestore_db } from '../../../config/firebase_config';
import { DeleteForever } from '@material-ui/icons';
import PlansSelector from 'common/PlansSelector';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteForever {...props} ref={ref} />)
};

export default class TableListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount = async () => {
        this.getData();
    };

    getData = async () => {
        let query = firestore_db.collection('categories');
        query.onSnapshot(snap => {
            console.log(snap);
            this.setState({
                data:
                    snap.size > 0
                        ? snap.docs.map(v => ({
                              id: v.id,
                              type:
                                  v.data().main_child_cat_id.length == 0
                                      ? v.data().main_cat_id[0] ==
                                        '7wEabmxn6zifUjVncqhU'
                                          ? 'Movies'
                                          : 'LIVE'
                                      : 'Movies child',
                              date: new Date(
                                  v.data().create_date
                              ).toDateString(),
                              ...v.data()
                          }))
                        : []
            });
        });
    };

    changeStatus = async ({ id }, status) => {
        await firestore_db.doc(`categories/${id}`).update({ status: (status == 1 ? true : false) })
        this.getData()    
    }

    onChangePlans = async(value, id) => {
        const updateValue = {
            'BASIC': false,
            'PREMIUM': false,
        }
        value.forEach((v) => {
            updateValue[v.id] = true
        })
        await firestore_db.doc(`categories/${id}`).update({ plans: updateValue })
        this.getData()   
    }

    render() {
        const { data } = this.state;
        console.log(data);
        return (
            <MaterialTable
                columns={[
                    { title: 'Category Name', field: 'category_name' },
                    { title: 'Type', field: 'type' },
                    {
                        title: 'Status', field: 'status',
                        render: (rowData) => <select value={(rowData.status ? 1 : 0)} onChange={(e) => this.changeStatus(rowData, e.target.value)}><option value={1}>Active</option><option value={0}>In Active</option></select>
                    },
                    {
                        title: 'Plans', field: 'plans',
                        render: (rowData) => <PlansSelector onChange={(_,val) => this.onChangePlans(val, rowData.id)} defaultValue={rowData.plans}/>                        
                    },
                    { title: 'Description', field: 'description' },
                    { title: 'Create Date', field: 'date' }
                ]}
                data={data}
                title=""
                icons={tableIcons}
                style={{
                    boxShadow: 'none !important'
                }}
                actions={[
                    // {
                    //     icon: tableIcons.Edit,
                    //     tooltip: 'Edit',
                    //     onClick: (event, rowData) => alert(rowData)
                    // },
                    {
                        icon: tableIcons.Delete,
                        tooltip: 'Delete',
                        onClick: async (event, rowData) =>
                            window.confirm('You want to delete?')
                                ? await firestore_db
                                      .doc('categories/' + rowData.id)
                                      .delete()
                                : null
                    }
                ]}
                options={{
                    actionsColumnIndex: -1
                }}
            />
        );
    }
}
