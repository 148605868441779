import Axios from 'axios';

let retData = {};
const MAIN_PATH = 'https://storage.bunnycdn.com/trivision-media/Video/',
    ACCESSKEY = '80adaa30-09c8-48ac-bda91b41cf79-ece8-4ce3';

export const bunnyData = async () => {
    return new Promise(async resolve => {
        let bunnyObjects = await Axios.get(MAIN_PATH, {
            headers: {
                AccessKey: ACCESSKEY
            }
        });
        // console.log('BUNNY OBJ', bunnyObjects);

        let promises = [];
        bunnyObjects.data.map(obj => {
            promises.push(
                new Promise(async resolve => {
                    resolve(await getSubFolderData(obj));
                })
            );
            console.log(obj);
        });

        await Promise.all(promises);

        resolve(retData);
    });
};

const getSubFolderData = async obj => {
    return new Promise(async resolve => {
        let bunnyObjects = await Axios.get(MAIN_PATH + obj.ObjectName + '/', {
            headers: {
                AccessKey: ACCESSKEY
            }
        });

        retData[obj.ObjectName] = bunnyObjects.data;
        resolve();
    });
};
