import React, { Component } from 'react';
// Externals
import PropTypes from 'prop-types';
import NotesDialog from "../../common/add_show_notes_dialog";
import { auth } from '../../config/firebase_config';
// Material helpers
import { withStyles, Button } from '@material-ui/core';

// Material components
import { Grid, Typography } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';
import AddNewAdButtons from './AddNewAdButtons';
import AdListing from './AdListing';
import { storage, firestore_db } from '../../config/firebase_config';
// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 4
    },
    content: {
        // marginTop: '150px',
        // textAlign: 'right',
        padding: '15px',
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
            border: '1px solid #dfe3e8',
            borderBottom: 'none'
        }
    }
});

class AdsListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notesUserObj: null
        }
    }

    _buildAdsData = async () => {
        let getAds = await firestore_db.collection('app_ads').get();

        if (getAds.size > 0) {
            let finalData = {
                left: [],
                right: []
            },
                length = getAds.docs.length;

            getAds.docs.map((ad, iDx) => {
                if (ad.data().type == 'ls') {
                    finalData.left.push(ad.data());
                } else {
                    finalData.right.push(ad.data());
                }
                // let limit = iDx + 1;
                if (length - 1 == iDx) {
                    console.log('DONE');
                    setTimeout(async () => {
                        console.log(
                            'SAVING DATA...',
                            JSON.stringify(finalData)
                        );
                        let apk = await firestore_db.doc('apks/apk').get();
                        const obj = {
                            ads: finalData,
                            version: apk.data().version,
                            apk_url: apk.data().apk_url
                        };
                        const blob = new Blob([JSON.stringify(obj, null, 2)], {
                            type: 'application/json'
                        });
                        var storageRef = storage
                            .ref('APP_DATA')
                            .child('ads.json');
                        var file = blob; // use the Blob or File API
                        storageRef
                            .put(file)
                            .then(function (snapshot) {
                                console.log('Uploaded a blob or file!');
                                alert('New data updated');
                            })
                            .catch(err => console.log(err));
                    }, 1500);
                }
            });
        }

        // let HOST = 'https://us-central1-tv-trivision.cloudfunctions.net';
        // // let HOST = 'http://localhost:5000/tv-trivision/us-central1';
        // let URL = HOST + '/buildLiveJSON';
        // const bodyOptions = {
        //     method: 'POST',
        //     body: JSON.stringify({
        //         typeId: 'ads'
        //     })
        // };

        // fetch(URL, bodyOptions)
        //     .then(async response => {
        //         let res = await response.json();
        //         console.log(res);
        //         if (res.status == 'DONE') {
        //             alert('New data updated');
        //         }
        //     })
        //     .then(serverErrors => {
        //         console.log(serverErrors);
        //     });
    };
    showNotes = () => {
        let user = auth.currentUser;
        this.setState({
            notesUserObj: {
                id: user.uid,
                note_user_type: "admin"
            }
        })

    }
    onNotesClose = () => {
        this.setState({
            notesUserObj: null
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <DashboardLayout title="Ads Listing">
                <Grid justify="center" spacing={4} className={classes.content}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            margin: '20px 0px'
                        }}>
                        <AddNewAdButtons edit={false} />
                        <Button variant="outlined" onClick={this._buildAdsData}>
                            Build Ads Data
                        </Button>&nbsp;&nbsp;
                        <Button variant="contained" onClick={() => this.showNotes()} >Show Notes</Button>
                    </div>
                    <AdListing />
                </Grid>
                {this.state.notesUserObj && <NotesDialog userData={this.state.notesUserObj} onClose={this.onNotesClose} />}
            </DashboardLayout>
        );
    }
}

export default withStyles(styles)(AdsListing);
