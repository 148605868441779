import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Button } from '@material-ui/core';

// Material components
import { Grid, Typography } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import TableListing from './Component/TableListing';

import AddChildNewCategory from './Component/AddChildNewCategory';

import VideoEpisodeList from '../Video/VideoEpisodeList';
import { firestore_db, storage } from '../../config/firebase_config.js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 4
    },
    content: {
        // marginTop: '150px',
        // textAlign: 'right',
        padding: '15px',
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
            border: '1px solid #dfe3e8',
            borderBottom: 'none'
        }
    }
});

class CategoryList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            data: [],
            isLoading: false,
            savingData: false
        };
    }
    _buildMoviesData = async () => {
        this.setState({
            isLoading: true
        });
        let docID = '7wEabmxn6zifUjVncqhU';
        let getCategories = await firestore_db
            .collection('categories')
            .where('main_cat_id', 'array-contains', docID)
            .orderBy('category_name', 'desc')
            .get();

        if (getCategories.size > 0) {
            let promises = [];
            getCategories.docs.map(async (doc, iDx) => {
                if (doc.data().main_child_cat_id[0] == null) {
                    // console.log(doc.data())
                    promises.push(
                        new Promise(async resolve => {
                            let data = {
                                child_category:
                                    doc.data().main_child_cat_id[0] == null
                                        ? await getChildCategory(doc.id)
                                        : [],
                                create_date: doc.data().create_date,
                                category_name: doc.data().category_name,
                                description: doc.data().description,
                                main_cat_id: doc.data().main_cat_id,
                                cat_image: doc.data().cat_image
                            };
                            setTimeout(() => resolve(data), iDx * 100);
                        })
                    );
                }
            });

            let resolvePromises = await Promise.all(promises);

            await new Promise(resolve => setTimeout(() => resolve(), 800));
            let vidPromises = [];
            resolvePromises.map(res => {
                console.log(res.child_category);
                if (res.child_category.length > 0) {
                    vidPromises.push(
                        new Promise(async resolve => {
                            res.child_category.map(async (doc, iDx) => {
                                doc.videos = await getVideosForCategories(
                                    doc.id
                                );
                                // doc.videos.map((v, i) => {
                                //     doc.videos[i] = {
                                //         ...v,
                                //         duration: Number(v.duration)
                                //     };
                                // });
                                setTimeout(
                                    () => resolve({ ...res }),
                                    iDx * 100
                                );
                            });
                        })
                    );
                } else {
                    vidPromises.push(
                        new Promise(async resolve => {
                            resolve({ ...res });
                            // res.child_category.map(async (doc, iDx) => {
                            //     doc.videos = [];resolve({ ...res }),
                            // });
                        })
                    );
                }
            });

            let resolveVidPromises = await Promise.all(vidPromises);
            console.log('[Promise video]', resolveVidPromises);

            await new Promise(resolve => setTimeout(() => resolve(), 800));

            this.setState({
                open: true,
                data: resolveVidPromises,
                isLoading: false
            });
        }
    };

    saveData = async () => {
        this.setState({
            savingData: true
        });
        let createFile = await this.createLocalFiles('Movies', {
            categories: this.state.data
        });
        if (createFile == 1) {
            this.setState({
                open: false,
                savingData: false
            });
        } else {
            console.log('[ERROR while file creation]');
        }
    };

    createLocalFiles = async (filePath, data) => {
        return new Promise(async resolve => {
            console.log('SAVING DATA...', JSON.stringify(data));
            const obj = data;
            const blob = new Blob([JSON.stringify(obj, null, 2)], {
                type: 'application/json'
            });
            var storageRef = storage.ref('APP_DATA').child('movies.json');
            var file = blob; // use the Blob or File API
            storageRef
                .put(file)
                .then(function(snapshot) {
                    console.log('Uploaded a blob or file!');
                    resolve(1);
                })
                .catch(err => console.log(err));
        });
    };
    render() {
        const { classes } = this.props;
        const { open, data, isLoading, savingData } = this.state;
        return (
            <DashboardLayout title="LIVE/Movies Listing">
                <Grid justify="center" spacing={4} className={classes.content}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            margin: '20px 0px'
                        }}>
                        <AddChildNewCategory />

                        {/* <Button
                            style={{
                                marginLeft: '20px'
                            }}
                            disabled={isLoading}
                            onClick={this._buildMoviesData}
                            color="secondary"
                            variant="contained">
                            {isLoading
                                ? 'Loading....'
                                : 'Verify & Save Movies Data'}
                        </Button> */}
                    </div>
                    <TableListing />

                    <VideoEpisodeList />
                </Grid>
                <Dialog
                    open={open}
                    onClose={true}
                    fullWidth
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {/* <code>{JSON.stringify(data)}</code> */}

                            <TreeView
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}>
                                {data.map((data, iDx) => (
                                    <TreeItem
                                        nodeId={iDx}
                                        label={`${data.category_name} (${data.child_category.length})`}>
                                        {data.child_category.map((d, i) => (
                                            <TreeItem
                                                nodeId={data.length + i}
                                                label={`${d.category_name} (${d.videos.length})`}></TreeItem>
                                        ))}
                                    </TreeItem>
                                ))}
                            </TreeView>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.setState({ open: false })}
                            color="primary">
                            Close
                        </Button>
                        <Button
                            onClick={() => this.saveData()}
                            color="primary"
                            variant="contained"
                            disabled={savingData}
                            autoFocus>
                            {savingData ? 'Saving....' : 'Save Data'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </DashboardLayout>
        );
    }
}

CategoryList.propTypes = {
    classes: PropTypes.object.isRequired
};

const getChildCategory = async id => {
    return new Promise(async resolve => {
        let videos = await firestore_db
            .collection('categories')
            .where('main_child_cat_id', 'array-contains', id)
            .orderBy('category_name', 'desc')
            .get();
        if (videos.size > 0) {
            let videoData = [],
                length = videos.docs.length;
            videos.docs.map((doc, iDx) => {
                videoData.push({ id: doc.id, ...doc.data() });
                if (length == iDx + 1) {
                    resolve(videoData);
                }
            });
        } else {
            resolve([]);
        }
    });
};

const getVideosForCategories = async data => {
    return new Promise(async resolve => {
        let videos = await firestore_db
            .collection('videos')
            .where('categories', 'array-contains', data)
            .get();
        if (videos.size > 0) {
            let videoData = [],
                length = videos.docs.length;
            videos.docs.map((doc, iDx) => {
                videoData.push({
                    ...doc.data(),
                    duration:
                        doc.data().duration == ''
                            ? 0
                            : parseFloat(doc.data().duration)
                });
                if (length == iDx + 1) {
                    resolve(videoData);
                }
            });
        } else {
            resolve([]);
        }
    });
};

export default withStyles(styles)(CategoryList);
