import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Button } from '@material-ui/core';

// Material components
import { forwardRef } from 'react';
import { DeleteForever } from '@material-ui/icons';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from 'material-table';
// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import VideoEpisodeList from '../Video/VideoEpisodeList';
import { firestore_db, storage } from '../../config/firebase_config.js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteForever {...props} ref={ref} />)
};


// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 4
    },
    content: {
        // marginTop: '150px',
        // textAlign: 'right',
        padding: '15px',
        '& .MuiPaper-elevation2': {
            boxShadow: 'none',
            border: '1px solid #dfe3e8',
            borderBottom: 'none'
        }
    }
});

class PaymentsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            data: [],
            isLoading: false,
            savingData: false
        };
    }
    componentDidMount = async () => {
        this.getData();
    };

    getData = async () => {
        let query = firestore_db.collection('payments');
        query.onSnapshot(snap => {
            console.log(snap);
            this.setState({
                data:
                    snap.size > 0
                        ? snap.docs.map(v => ({
                            id: v.id,
                            ...v.data()
                        }))
                        : []
            });
        });
    };

    render() {
        const { classes } = this.props;
        const { open, data, isLoading, savingData } = this.state;

        return (
            <DashboardLayout title="Payments">
                <MaterialTable columns={[
                    { title: 'User Id', field: 'user_id' },
                ]} data={data} title=""
                    icons={tableIcons}
                    style={{
                        boxShadow: 'none !important'
                    }} />
            </DashboardLayout>
        );
    }
}

PaymentsList.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(PaymentsList);
