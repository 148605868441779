import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    TextField,
    FormControl,
    Input,
    Select,
    InputLabel,
    MenuItem,
    Checkbox,
    ListItemText,
    Box,
    LinearProgress
} from '@material-ui/core';
import _ from 'underscore';
import validate from 'validate.js';
import { firestore_db } from '../../../config/firebase_config';
import FileUploader from 'react-firebase-file-uploader';
import firebase from 'firebase';

const schema = {
    name: {
        presence: { allowEmpty: false, message: 'is required' }
    },
    description: {
        presence: { allowEmpty: false, message: 'is required' }
    }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

class AddChilNewCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddDialog: false,
            values: {
                name: '',
                description: 'Demo description, edit if you want',
                main_cat_id: [],
                main_child_cat_id: [],
                cat_image: '',
                imageUploadProgress: 0,
                isUploading: false
            },
            touched: {
                name: false,
                description: false
            },
            errors: {
                name: null,
                description: null
            },
            isValid: false,
            isLoading: false,
            submitError: null,
            mainCategories: [],
            showChildCat: false,
            mainChildCategories: []
        };

        this.handlePhotoUploadStart = this.handlePhotoUploadStart.bind(this);
        this.handlePhotoUploadProgress = this.handlePhotoUploadProgress.bind(
            this
        );
        this.handlePhotoUploadError = this.handlePhotoUploadError.bind(this);
        this.handlePhotoUploadSuccess = this.handlePhotoUploadSuccess.bind(
            this
        );
    }

    componentDidMount = async () => {
        setTimeout(() => this.getCanData(), 1500);
    };

    getCanData = () => {
        const { values } = this.state;
        firestore_db
            .collection('main_categories')
            .where('category_name', 'in', ['LIVE', 'Movies'])
            .get()
            .then(mainCat => {
                console.log(mainCat, '---');
                this.setState({
                    mainCategories:
                        mainCat.size > 0
                            ? mainCat.docs.map(v => ({ id: v.id, ...v.data() }))
                            : []
                });
            });
    };

    validateForm = _.debounce(() => {
        const { values } = this.state;

        const newState = { ...this.state };
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = errors ? false : true;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };

        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    _addNewCat = async () => {
        const { showAddDialog, values } = this.state;
        if (showAddDialog) {
            if (values.name !== '') {
                window.location.reload();
            }
        }
        this.setState({
            showAddDialog: !showAddDialog
        });
    };

    _submitForm = async () => {
        const {
            values,
            mainCategories,
            mainChildCategories,
            showChildCat
        } = this.state;
        console.log(values);
        let data = {
            main_cat_id: values.main_cat_id.map(v =>
                mainCategories.find(x => x.category_name == v)
                    ? mainCategories.find(x => x.category_name == v).id
                    : null
            ),
            main_child_cat_id: values.main_child_cat_id.map(v =>
                mainChildCategories.find(x => x.category_name == v)
                    ? mainChildCategories.find(x => x.category_name == v).id
                    : null
            ),
            category_name: values.name,
            description: values.description,
            cat_image: showChildCat ? values.cat_image : ' ',
            status: true,
            plans: {
            'BASIC': true,
            'PREMIUM': true,
            },
            create_date: Date.now()
        };
        console.log(data);
        await firestore_db
            .collection('categories')
            .doc()
            .set(data);
        this._addNewCat();
    };

    _handleMainCatChange = async e => {
        const { values, mainChildCategories } = this.state;
        values.main_cat_id = [e.target.value];
        if (e.target.value == 'Movies') {
            this.setState({
                showChildCat: true
            });
            if (mainChildCategories.length == 0) {
                firestore_db
                    .collection('categories')
                    .where(
                        'main_cat_id',
                        'array-contains',
                        '7wEabmxn6zifUjVncqhU'
                    )
                    .onSnapshot(childCat => {
                        let finalCat = [];

                        if (childCat.size > 0) {
                            let dataLen = childCat.docs.length;
                            childCat.docs.map((v, iDx) => {
                                console.log(v.data().main_child_cat_id);
                                if (v.data().main_child_cat_id.length == 0) {
                                    finalCat.push({
                                        id: v.id,
                                        ...v.data()
                                    });
                                }
                                if (dataLen == iDx + 1) {
                                    this.setState({
                                        mainChildCategories: finalCat
                                    });
                                }
                            });
                        } else {
                            this.setState({
                                mainChildCategories: []
                            });
                        }
                        // this.setState({
                        //     mainChildCategories:
                        //         childCat.size > 0
                        //             ? childCat.docs.map(v => (v.data().main_child_cat_id.length == 0 )  ({
                        //                   id: v.id,
                        //                   ...v.data()
                        //               }))
                        //             : []
                        // });
                    });
            }
        } else {
            // values.main_cat_id = [];
            this.setState({
                showChildCat: false
            });
        }
        this.setState({ ...values });
    };

    _handleMainChildCatChange = async e => {
        const { values } = this.state;
        values.main_child_cat_id = [e.target.value];
        this.setState({ ...values });
    };

    handlePhotoUploadStart() {
        this.setState({ isUploading: true, progress: 0 });
    }
    handlePhotoUploadProgress(progress) {
        this.setState({ imageUploadProgress: progress });
    }
    handlePhotoUploadError(error) {
        this.setState({ isUploading: false });
    }
    handlePhotoUploadSuccess(filename) {
        this.setState({
            filename: filename,
            progress: 100,
            isUploading: false
        });
        firebase
            .storage()
            .ref('Photo')
            .child(filename)
            .getDownloadURL()
            .then(url => this.handleFieldChange('cat_image', url));
    }

    render() {
        const {
            showAddDialog,
            values,
            touched,
            errors,
            isValid,
            showChildCat,
            submitError,
            mainCategories,
            mainChildCategories
        } = this.state;

        const showNameError = touched.name && errors.name;
        const showDescriptionError = touched.description && errors.description;

        return (
            <>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this._addNewCat}>
                    Add new Category
                </Button>
                <Dialog open={showAddDialog} fullWidth maxWidth={'sm'}>
                    <DialogTitle>
                        <Typography component="h3" variant="h3">
                            Add New Category
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{
                                    margin: '10px 10px'
                                }}>
                                <InputLabel id="demo-mutiple-checkbox-label">
                                    Main Category
                                </InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    // multiple
                                    value={values.main_cat_id}
                                    onChange={this._handleMainCatChange}
                                    input={<Input />}
                                    renderValue={selected => selected}
                                    variant="outlined"
                                    MenuProps={MenuProps}>
                                    {mainCategories.map(name => (
                                        <MenuItem
                                            key={name.id}
                                            value={name.category_name}>
                                            <Checkbox
                                                checked={
                                                    values.main_cat_id.indexOf(
                                                        name.category_name
                                                    ) > -1
                                                }
                                            />
                                            <ListItemText
                                                primary={name.category_name}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                style={{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Paste Image URL"
                                name="image_url"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'cat_image',
                                        event.target.value
                                    )
                                }
                                type="url"
                                value={values.cat_image}
                                variant="outlined"
                            />
                            <div
                                style={{
                                    background: '#4682b3',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                    marginBottom: '20px',
                                    marginRight: '-10px'
                                }}>
                                <label
                                    style={{
                                        color: 'white',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        margin: 'auto',
                                        height: '25px',
                                        padding: '3px'
                                    }}>
                                    <Typography style={{ color: '#FFF' }}>
                                        Select Image
                                    </Typography>
                                    <FileUploader
                                        hidden
                                        accept="image/*"
                                        storageRef={firebase
                                            .storage()
                                            .ref('Photo')}
                                        onUploadStart={
                                            this.handlePhotoUploadStart
                                        }
                                        onUploadError={
                                            this.handlePhotoUploadError
                                        }
                                        onUploadSuccess={
                                            this.handlePhotoUploadSuccess
                                        }
                                        onProgress={
                                            this.handlePhotoUploadProgress
                                        }
                                    />
                                </label>
                            </div>
                            {this.state.isUploading && (
                                <div
                                    className="upload-progressbar"
                                    style={{
                                        width: '100%',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={Math.round(
                                            this.state.imageUploadProgress,
                                            2
                                        )}
                                        color="secondary"
                                    />
                                </div>
                            )}

                            <TextField
                                style={{
                                    margin: '5px 10px'
                                }}
                                fullWidth
                                label="Category Name"
                                name="name"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'name',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.name}
                                variant="outlined"
                            />
                            {showNameError && (
                                <Typography variant="body2">
                                    {errors.name[0]}
                                </Typography>
                            )}
                            {showChildCat && (
                                <div>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        style={{
                                            margin: '10px 10px'
                                        }}>
                                        <InputLabel id="demo-mutiple-checkbox-label">
                                            Child Category
                                        </InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            // multiple
                                            value={values.main_child_cat_id}
                                            onChange={
                                                this._handleMainChildCatChange
                                            }
                                            input={<Input />}
                                            renderValue={selected => selected}
                                            variant="outlined"
                                            MenuProps={MenuProps}>
                                            {mainChildCategories.map(name => (
                                                <MenuItem
                                                    key={name.id}
                                                    value={name.category_name}>
                                                    <Checkbox
                                                        checked={
                                                            values.main_child_cat_id.indexOf(
                                                                name.category_name
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText
                                                        primary={
                                                            name.category_name
                                                        }
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br />
                                </div>
                            )}

                            <TextField
                                style={{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Description"
                                name="description"
                                rows={5}
                                multiline
                                onChange={event =>
                                    this.handleFieldChange(
                                        'description',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.description}
                                variant="outlined"
                            />
                            {showDescriptionError && (
                                <Typography variant="body2">
                                    {errors.description[0]}
                                </Typography>
                            )}
                        </div>
                        {submitError && (
                            <Typography variant="body2">
                                {submitError}
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this._addNewCat}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this._submitForm}
                            disabled={!isValid}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default AddChilNewCategory;
