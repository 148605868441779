import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Button, IconButton } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    LinearProgress,
    TextField,
    Avatar
} from '@material-ui/core';
import _ from 'underscore';
// import Collapse from '@material-ui/core/Collapse';
import validate from 'validate.js';
import { firestore_db } from '../../config/firebase_config';
import { Delete, Edit } from '@material-ui/icons';
import FileUploader from 'react-firebase-file-uploader';
import firebase from 'firebase';
import BunnyComponent from 'views/BunnyComponent';

const schema = {
    name: {
        presence: { allowEmpty: false, message: 'is required' }
    }
};

export default class AddNewAd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddDialog: false,
            values: {
                name: '',
                description: '',
                cat_image: ''
            },
            touched: {
                name: false,
                description: false
            },
            errors: {
                name: null,
                description: null
            },
            isValid: false,
            isLoading: false,
            submitError: null,
            imageUploadProgress: 0,
            isUploading: false
        };
        this.handlePhotoUploadStart = this.handlePhotoUploadStart.bind(this);
        this.handlePhotoUploadProgress = this.handlePhotoUploadProgress.bind(
            this
        );
        this.handlePhotoUploadError = this.handlePhotoUploadError.bind(this);
        this.handlePhotoUploadSuccess = this.handlePhotoUploadSuccess.bind(
            this
        );
        this._getBunnySelected = this._getBunnySelected.bind(this);
    }

    componentDidMount = async () => {
        const { edit, editData } = this.props;
        if (edit) {
            console.log(editData);
            this.setState({
                values: {
                    name: editData.category_name,
                    description: editData.description,
                    cat_image: editData.cat_image,
                    video_url: editData.video_url,
                    create_date: editData.create_date,
                    type: editData.type
                }
            });
        }
    };

    validateForm = _.debounce(() => {
        const { values } = this.state;

        const newState = { ...this.state };
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = errors ? false : true;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };

        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    _addNewCat = async () => {
        const { showAddDialog } = this.state;
        if (showAddDialog && !this.props.edit) {
            this.setState({
                values: {
                    name: '',
                    description: '',
                    cat_image: '',
                    video_url: ''
                },
                touched: {
                    name: false,
                    description: false
                },
                errors: {
                    name: null,
                    description: null
                },
                isValid: false,
                isLoading: false,
                submitError: null,
                imageUploadProgress: 0,
                isUploading: false
            });
        }
        this.setState({
            showAddDialog: !showAddDialog
        });
    };

    _submitForm = async () => {
        const { values } = this.state;
        console.log(values);
        let data = {
            category_name: values.name,
            description: values.description,
            cat_image: values.cat_image,
            create_date: this.props.edit ? values.create_date : Date.now(),
            video_url: values.video_url ? values.video_url : '',
            // subData: [],
            type: this.props.edit ? values.type : this.props.slug
        };

        if (this.props.edit) {
            await firestore_db
                .collection('app_ads')
                .doc(this.props.editData.id)
                .update(data);
        } else {
            await firestore_db
                .collection('app_ads')
                .doc()
                .set(data);
        }
        window.location.reload();
        this._addNewCat();
        // this.props.getData();
    };

    handlePhotoUploadStart() {
        this.setState({ isUploading: true, progress: 0 });
    }
    handlePhotoUploadProgress(progress) {
        this.setState({ imageUploadProgress: progress });
    }
    handlePhotoUploadError(error) {
        this.setState({ isUploading: false });
    }
    handlePhotoUploadSuccess(filename) {
        this.setState({
            filename: filename,
            progress: 100,
            isUploading: false
        });
        firebase
            .storage()
            .ref('Photo')
            .child(filename)
            .getDownloadURL()
            .then(url => this.handleFieldChange('cat_image', url));
    }

    callBack = async (image, video, duration) => {
        const { values } = this.state;
        values.cat_image = image;
        values.video_url = video;
        this.setState({ ...values });
    };

    _getBunnySelected = async video => {
        console.log(video);
        let url = `https://trivisiontv.b-cdn.net/Video/${
            video.Path.split('/').reverse()[1]
        }/${video.ObjectName}`;
        this.handleFieldChange('video_url', url);
    };

    render() {
        const {
            showAddDialog,
            values,
            touched,
            errors,
            isValid,
            submitError,
            isLoading
        } = this.state;

        const showNameError = touched.name && errors.name;
        const showDescriptionError = touched.description && errors.description;

        return (
            <>
                {this.props.edit ? (
                    <IconButton>
                        <Edit onClick={this._addNewCat} />
                    </IconButton>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            marginRight: '20px'
                        }}
                        onClick={this._addNewCat}>
                        {this.props.slug == 'ls'
                            ? 'Add new Left side Ads'
                            : 'Add new Right side Ads'}
                    </Button>
                )}
                <Dialog open={showAddDialog} fullWidth maxWidth={'sm'}>
                    <DialogTitle>
                        <Typography component="h3" variant="h3">
                            {this.props.edit ? 'Edit Ad' : 'Add New Ads'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        {/* <Box
                            style={{
                                margin: '10px 10px',
                                textAlign: 'center'
                            }}>
                            <VideoUpload callBack={this.callBack.bind(this)} />
                        </Box> */}
                        <BunnyComponent
                            getBunnySelected={this._getBunnySelected}
                        />
                        <TextField
                            style={{
                                margin: '10px 10px 20px 10px'
                            }}
                            fullWidth
                            label="Paste Video URL"
                            name="url"
                            onChange={event =>
                                this.handleFieldChange(
                                    'video_url',
                                    event.target.value
                                )
                            }
                            type="text"
                            value={values.video_url}
                            variant="outlined"
                        />
                        <Box
                            mt={2}
                            mb={2}
                            style={{
                                display: 'flex',
                                // flexDirection: 'column',
                                flex: 1
                            }}>
                            <TextField
                                style={{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Paste Ad Image URL"
                                name="image_url"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'cat_image',
                                        event.target.value
                                    )
                                }
                                type="url"
                                value={values.cat_image}
                                variant="outlined"
                            />
                            <label
                                style={{
                                    backgroundColor: 'steelblue',
                                    color: 'white',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    margin: 'auto',
                                    height: '25px',
                                    padding: '3px'
                                }}>
                                <Typography style={{ color: '#FFF' }}>
                                    Select Image
                                </Typography>
                                <FileUploader
                                    hidden
                                    accept="image/*"
                                    randomizeFilename
                                    storageRef={firebase.storage().ref('Photo')}
                                    onUploadStart={this.handlePhotoUploadStart}
                                    onUploadError={this.handlePhotoUploadError}
                                    onUploadSuccess={
                                        this.handlePhotoUploadSuccess
                                    }
                                    onProgress={this.handlePhotoUploadProgress}
                                />
                            </label>
                            {this.state.isUploading && (
                                <div
                                    className="upload-progressbar"
                                    style={{
                                        width: '90%'
                                    }}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={Math.round(
                                            this.state.imageUploadProgress,
                                            2
                                        )}
                                        color="secondary"
                                    />
                                </div>
                            )}
                        </Box>
                        <div>
                            <TextField
                                style={{
                                    margin: '5px 10px'
                                }}
                                fullWidth
                                label="Ad title"
                                name="name"
                                onChange={event =>
                                    this.handleFieldChange(
                                        'name',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.name}
                                variant="outlined"
                            />
                            {showNameError && (
                                <Typography variant="body2">
                                    {errors.name[0]}
                                </Typography>
                            )}
                            <TextField
                                style={{
                                    margin: '10px 10px'
                                }}
                                fullWidth
                                label="Description"
                                name="description"
                                rows={5}
                                multiline
                                onChange={event =>
                                    this.handleFieldChange(
                                        'description',
                                        event.target.value
                                    )
                                }
                                type="text"
                                value={values.description}
                                variant="outlined"
                            />
                            {showDescriptionError && (
                                <Typography variant="body2">
                                    {errors.description[0]}
                                </Typography>
                            )}
                        </div>
                        {submitError && (
                            <Typography variant="body2">
                                {submitError}
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this._addNewCat}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this._submitForm}
                            disabled={!isValid}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
