import React, { useState } from 'react';
let getDate = (payment) => {
    return payment.created.toString().length <= 10 ? new Date(payment.created * 1000).toLocaleString() : new Date(payment.created).toLocaleString()

}
const PaymentHistoryItem = ({ history }) => {
    const onDownloadINvoce = async () => {

    }
    return (
        < >
            <p>{getDate(history)}</p></>)
}

export default PaymentHistoryItem