import React from 'react';
import { firestore_db } from '../../config/firebase_config';
import TableListing from './TableListing';

import AddNewVideo from './AddNewVideo';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

class VideoEpisodeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount = async () => {
        this.getData();
    };

    getData = async () => {
        let query = firestore_db.collection('videos');
        query.onSnapshot(snap => {
            // console.log(snap);
            this.setState({
                data:
                    snap.size > 0
                        ? snap.docs.map(v => ({
                              id: v.id,
                              date: new Date(
                                  v.data().create_date
                              ).toDateString(),
                              ...v.data()
                          }))
                        : []
            });
        });
    };

    render() {
        return (
            // <DashboardLayout title="Video Listing">
            <div
                style={{
                    padding: '10px'
                }}>
                <div
                    style={{
                        textAlign: 'right',
                        margin: '20px'
                    }}>
                    <AddNewVideo getData={this.getData} edit={false} />
                </div>
                <TableListing data={this.state.data} />
            </div>
            // {/* </DashboardLayout> */}
        );
    }
}

export default VideoEpisodeList;
