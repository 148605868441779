import React from 'react';

// Views
import Dashboard from './views/Dashboard';
// import ProductList from './views/ProductList';
// import UserList from './views/UserList';
// import Typography from './views/Typography';
// import Icons from './views/Icons';
// import Account from './views/Account';
// import Settings from './views/Settings';
// import SignUp from './views/SignUp';
import SignIn from './views/SignIn';
// import UnderDevelopment from './views/UnderDevelopment';
// import NotFound from './views/NotFound';
import CategoryList from './views/Category/CategoryList';
import PaymentList from './views/Payments/PaymentList';
// import VideoEpisodeList from './views/Video/VideoEpisodeList';
import VODListing from './views/VOD/VODListing';
import VODListingV2 from './views/VODV2/VODListingV2';
import AdsComponent from './views/AdsComponent/AdsListing';
import Apks from 'views/Apks';
import Users from 'views/Users/Users';
import CreateCode from 'views/Codes/CreateCode';
import Registrations from 'views/Users/Registrations';

const ROUTES = [
    {
        component: Dashboard,
        exact: true,
        path: '/dashboard',
        title: 'Dashboard'
    },
    {
        component: SignIn,
        exact: true,
        path: '/signin',
        title: 'signin'
    },
    {
        component: CategoryList,
        exact: true,
        path: '/category',
        title: 'LIVE/Movies List'
    },
    // {
    //     component: PaymentList,
    //     exact: true,
    //     path: '/payments',
    //     title: 'Payments'
    // },
    // {
    //     component: VODListing,
    //     exact: true,
    //     path: '/vod',
    //     title: 'VOD List'
    // },
    {
        component: VODListingV2,
        exact: true,
        path: '/vod-v2',
        title: 'VOD List'
    },
    {
        component: AdsComponent,
        exact: true,
        path: '/ads',
        title: 'Ads Listing'
    },
    {
        component: Apks,
        exact: true,
        path: '/apks',
        title: 'APKs'
    },
    {
        component: Users,
        exact: true,
        path: '/users',
        title: 'Users'
    },
    {
        component: Registrations,
        exact: true,
        path: '/registration',
        title: 'Registration'
    },
    {
        component: CreateCode,
        exact: true,
        path: '/codes',
        title: 'Codes'
    }
];

export default ROUTES;
