import React, {forwardRef} from 'react';
import { Dashboard as DashboardLayout } from 'layouts';
import { useEffect } from 'react';
import { useState } from 'react';
import { firestore_db } from '../../config/firebase_config';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import { Box, Checkbox, TextField } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import copy from 'copy-to-clipboard';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  JSON: forwardRef((props, ref) => <CloudCircleIcon {...props} ref={ref} />),
  Copy: forwardRef((props, ref) => <FileCopy {...props} ref={ref} />)
};

const CreateCode = () => {

  const [refreshCodes, setRefreshCodes] = useState(false);
  const [codes, setCodes] = useState([]);
  const [codeNumber, setCodeNumber] = useState(10);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    firestore_db.collection('codes').get().then((res) => {
      setCodes(res.docs.map((d) => ({
        id: d.id,
        code: d.data().code,
        status: d.data().status ? 'Open' : 'Used',
        verified: d.data().verified || false
      })));
    });
  }, [refreshCodes]);

  const makeCode = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
      charactersLength));
    }
    return result;
  } 


  const createCodes = (e) => {
    e.preventDefault();
    setProcessing(true);
    Array.from(Array(codeNumber)).forEach(async() => {
      await firestore_db.collection('codes').add({code: makeCode(6), status: true, created: Date.now()})
    })
    setProcessing(false);
    setRefreshCodes(!refreshCodes);
  }

  const verifiedChanges = async (id, value) => {
    await firestore_db.doc(`codes/${id}`).update({ verified: value });
    console.log('done')
    setRefreshCodes(!refreshCodes);
  }

  return(
    <DashboardLayout title="Codes Listing">
      
      <form onSubmit={createCodes}>
        <Box p={2} mb={1}>
          <TextField 
            value={codeNumber}
            onChange={(e) => setCodeNumber(e.target.value)}
            type="number"
            variant="outlined"
            label={`Enter Number & Hit Enter`}
            fullWidth disabled={processing}/>
        </Box>
      </form>
      <Box p={2} mb={1}>
        <MaterialTable
          columns={[
            { title: 'Code', field: 'code' },
            { title: 'Status', field: 'status' },
            { title: 'Verified', field: 'verified', render: (rowData) => <Checkbox defaultChecked={rowData.verified || false} size='small' onChange={(e)=> verifiedChanges(rowData.id, e.target.checked)}/> },
          ]}
          data={codes}
          icons={tableIcons}
          title=""
          actions={[
            {
                icon: tableIcons.Copy,
                tooltip: 'Copy',
                onClick: async (event, rowData) => {
                  await copy(rowData.code)
                  alert('Code copied')
                }
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            exportButton: {
              csv: true,
            }
          }}
          style={{
            boxShadow: 'none !important'
          }}
        />
      </Box>
    </DashboardLayout>
  )
}

export default CreateCode;