import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@material-ui/core';
import { firestore_db } from '../config/firebase_config';
import { useState, useEffect, useRef } from 'react'
import React from 'react';
import ContentLoader from 'react-content-loader'

import './App.css';
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as Addicon } from '../icons/add.svg'
import { range } from 'underscore';
import PaymentHistoryItem from './payment_history_items';
import { async } from 'validate.js';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
const PaymentHistoryDialog = ({ onClose, userData }) => {

    const renderAfterCalled = useRef(false);

    const [paymentHistoryList, setPaymentHistoryList] = useState([])

    useEffect(() => {
        if (!renderAfterCalled.current) {
            fetchData();
        }
    })
    async function fetchData() {
        let data = await firestore_db.collection('payments').where("user_id", "==", userData['id']).orderBy('create_time', 'desc').get();
        var paymentListObj = data.docs.map(v => v.data());
        renderAfterCalled.current = true;
        setPaymentHistoryList(paymentListObj)

    }



    return (
        <div>
            <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
                <DialogTitle>History</DialogTitle>
                <DialogContent style={{ height: '600px' }}>
                    <div>
                        {!renderAfterCalled.current ? range(1, 10).map((index) => { return (<ContentLoader key={index} />) }) : paymentHistoryList.length > 0 ? paymentHistoryList.map((paymentObj, index) => {
                            return (
                                <PaymentHistoryItem key={paymentObj.id} history={paymentObj} ></PaymentHistoryItem>
                            )
                        }) : <div className='center'> No Payment History avaliable</div>}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default PaymentHistoryDialog;