import { Divider } from '@material-ui/core';
import './App.css';
import React, { useState } from 'react';
import Delete from '@material-ui/icons/Delete';
import { firestore_db } from '../config/firebase_config';
let getDate = (note) => {
    return new Date(note.create_time).toLocaleString()
}

const ReadMore = ({ onDelete, notes }) => {
    const text = notes;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (

        <li>

            <p >
                {isReadMore ? text.slice(0, 70) : text}
                {text.length < 70 ? null : <span
                    onClick={toggleReadMore}
                    className="read-or-hide"
                    style={{ color: "blue" }}
                >
                    {isReadMore ? "   ...read more" : " ...show less"}
                </span>}
            </p>
            <Delete className='delete_icon' onClick={onDelete}></Delete>
        </li>
    );
};

const NotesItem = ({ onDelete, note }) => {
    const onDeleteNotes = async () => {
        await firestore_db.collection('notes').doc(note.id)
            .delete();
        onDelete();
    }
    return (
        < >
            <div className='notes-list-item'>
                <ReadMore onDelete={onDeleteNotes} notes={note.notes} ></ReadMore>
                <p>{getDate(note)}</p>
            </div>
            <Divider></Divider>
        </>
    )
}

export default NotesItem