import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { firestore_db } from '../../config/firebase_config';
import { Delete } from '@material-ui/icons';
import {
    Typography,
    IconButton,
    Button,
    TextField,
    Box
} from '@material-ui/core';
import VODSubOfSubCategory from './VODSubOfSubCategory';

const INITIAL_STATE = {
    sub_cat_name: '',
    subData2: []
};
class VODSubCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showInput: false,
            values: { ...INITIAL_STATE }
        };
    }

    _addnewSubCat = async () => {
        if (this.state.showInput) {
            this.setState({
                values: { ...INITIAL_STATE }
            });
        }
        this.setState({
            showInput: !this.state.showInput
        });
    };

    _createNewSubCat = async () => {
        const { values } = this.state;
        if (values.sub_cat_name.trim() !== '') {
            let udata = { ...this.props.data };
            udata.subData.push(values);
            await firestore_db
                .doc('main_vod_categories/' + this.props.dataId)
                .update(udata);
            // window.location.reload();
            this._addnewSubCat();
        }
    };

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };
        newState.values[field] = value;
        this.setState({ ...newState });
    };

    deleteSubCat = async (cat, iDx) => {
        const { data } = this.props;

        if (window.confirm('Delete the item?')) {
            data.subData.splice(iDx, 1);
            await firestore_db
                .doc('main_vod_categories/' + this.props.dataId)
                .update(data);
            // window.location.reload();
            this._addnewSubCat();
        }
    };

    render() {
        const { dataId, data } = this.props;
        const { values, showInput } = this.state;
        const isValid = values.sub_cat_name !== '';

        return (
            <div
                style={{
                    flex: 1
                }}>
                <Button
                    onClick={() => this._addnewSubCat()}
                    variant="outlined"
                    color="primary"
                    style={{ marginBottom: showInput ? 0 : 20 }}>
                    Add New Sub-Category
                </Button>
                {showInput && (
                    <Box
                        mt={2}
                        mb={2}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flex: 1
                        }}>
                        <TextField
                            style={{
                                margin: '10px 10px'
                            }}
                            fullWidth
                            label="Sub-Category Name"
                            name="name"
                            onChange={event =>
                                this.handleFieldChange(
                                    'sub_cat_name',
                                    event.target.value
                                )
                            }
                            type="text"
                            value={values.sub_cat_name}
                            variant="outlined"
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            disabled={!isValid}
                            onClick={this._createNewSubCat}
                            style={{ height: 40, margin: 'auto' }}>
                            Submit
                        </Button>
                    </Box>
                )}
                {data.subData.map((doc, iDx) => {
                    return (
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={'panel1bh-content-sub' + iDx}
                                id={'panel1bh-header_sub' + iDx}>
                                <Typography>{doc.sub_cat_name}</Typography>
                                <div
                                    style={{
                                        flex: 1,
                                        textAlign: 'end'
                                    }}>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            this.deleteSubCat(doc, iDx)
                                        }>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <VODSubOfSubCategory
                                    dataId={data.id}
                                    data={doc}
                                    iDx={iDx}
                                    fullData={data}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    );
                })}
                {/* {dataId} */}
            </div>
        );
    }
}

export default VODSubCategory;
