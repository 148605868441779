import React from 'react';
import AddNewVideo from './AddNewVideo';
import { Box, Grid, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Delete } from '@material-ui/icons';
import { firestore_db } from '../../config/firebase_config';

class VideoListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flag: false
        };
    }

    deleteVideo = async iDx => {
        const { data } = this.props;
        if (window.confirm('Want to delete this video?')) {
            data.subData[this.props.parendiDx].subData2[
                this.props.iDx
            ].videos.splice(iDx, 1);
            await firestore_db
                .doc('main_vod_categories/' + this.props.dataId)
                .update(data);
            // window.location.reload();
        }
    };

    _setFlag = () => {
        this.setState({ flag: true });
    };

    render() {
        const { document } = this.props;
        const { flag } = this.state;
        return (
            <Box>
                {flag && <AddNewVideo {...this.props} edit={false} />}
                <Box mt={2}>
                    {!flag && (
                        <Button onClick={this._setFlag}>Show Videos</Button>
                    )}
                    {flag && (
                        <Grid container spacing={2}>
                            {document.videos.map((vid, iDx) => {
                                return (
                                    <Grid item xs={4}>
                                        <Card
                                            style={{
                                                minWidth: '150px'
                                            }}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    alt={vid.title}
                                                    height="80"
                                                    image={vid.video_img_url}
                                                    title={vid.title}
                                                />
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="h2">
                                                        {vid.title}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p">
                                                        {vid.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <IconButton
                                                    onClick={() =>
                                                        this.deleteVideo(iDx)
                                                    }>
                                                    <Delete />
                                                </IconButton>
                                                <AddNewVideo
                                                    edit={true}
                                                    editData={vid}
                                                    {...this.props}
                                                    editVidIdx={iDx}
                                                />
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                </Box>
            </Box>
        );
    }
}

export default VideoListing;
