import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ROUTES from './routes';

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Redirect
          exact
          from="/"
          to="/dashboard"
        />
        {ROUTES.map((route) => {
          return(
            <Route
              component={route.component}
              exact={route.extact}
              path={route.path}
            />
          )
        })}
      </Switch>
    );
  }
}
