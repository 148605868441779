import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { firestore_db } from '../../config/firebase_config';
import React from 'react';
import { useState } from 'react';

const EditDialog = ({ onClose, data }) => {
  const [editData, setEditData] = useState({
    name: data.name,
    phone: data.phone_number,
    mac: data.macaddress,
    date: data.date,
  })
  const [processing, setProcessing] = useState(false);

  const updateValue = (field, value) => {
    setEditData({
      ...editData,
      [field]: value
    })
  }

  const saveData = async () => {
    setProcessing(true);
    var isValid = isAfterNow(editData.date);
    if (isValid) {
      const payload = {
        name: editData.name,
        phone_number: editData.phone,
        macaddress: editData.mac,
        register_date: new Date(editData.date).getTime()
      }
      await firestore_db.collection('users').doc(data.id).update(payload)
      setProcessing(false);
      onClose(true);
    } else {
      setProcessing(false);
      alert('Please add correct date');
    }
  }
  function isDateBeforeToday(date) {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
  } function isAfterNow(date) {
    return new Date().valueOf() > new Date(date).valueOf();
  }
  return (
    <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <TextField
          onChange={(e) => updateValue('name', e.target.value)}
          value={editData.name}
          variant="outlined"
          fullWidth
          label="Name"
          style={{ marginBottom: 10 }}
        />
        <TextField
          onChange={(e) => updateValue('phone', e.target.value)}
          value={editData.phone}
          variant="outlined"
          fullWidth
          label="Phone Number"
          style={{ marginBottom: 10 }}
        />
        <TextField
          onChange={(e) => updateValue('mac', e.target.value)}
          value={editData.mac}
          variant="outlined"
          fullWidth
          label="Mac Address"
          style={{ marginBottom: 10 }}
        />
        <TextField
          onChange={(e) => updateValue('date', e.target.value)}
          value={editData.date}
          variant="outlined"
          fullWidth
          label="Create Date"
          style={{ marginBottom: 10 }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color="default" onClick={onClose}>Close</Button>
        <Button variant='contained' color="primary" onClick={saveData} disabled={processing}>Update</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditDialog;