import React from 'react';
import { bunnyData } from 'helpers/bunnyObject';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { Button } from '@material-ui/core';

export default class BunnyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount = async () => {
        let videoData = await bunnyData();
        console.log('BUNNY DATA', videoData);
        this.setState({
            data: videoData
        });
    };

    assignVideo = async vid => this.props.getBunnySelected(vid);

    render() {
        const { data } = this.state;
        return (
            <div
                style={{
                    padding: '20px'
                }}>
                {Object.entries(data).map((v, index) => {
                    return (
                        <TreeView
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}>
                            <TreeItem
                                nodeId={index}
                                label={v[0]}
                                style={{
                                    maxHeight: '400px',
                                    overflow: 'scroll'
                                }}>
                                {v[1].map((sub, iDX) => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                border: '0.5px solid #9E9E9E',
                                                alignItems: 'center',
                                                borderRadius: '5px',
                                                margin: '10px'
                                            }}>
                                            <TreeItem
                                                nodeId={iDX}
                                                label={sub.ObjectName}
                                            />
                                            <Button
                                                onClick={() =>
                                                    this.assignVideo(sub)
                                                }>
                                                Assign
                                            </Button>
                                        </div>
                                    );
                                })}
                            </TreeItem>
                        </TreeView>
                    );
                })}
            </div>
        );
    }
}
