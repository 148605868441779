import React from 'react';
import { Dashboard as DashboardLayout } from 'layouts';
import { Box, Button, TextField } from '@material-ui/core';
import { useState } from 'react';
import Axios from 'axios';
import { cloudUrl } from '../../config/firebase_config';
import { Alert } from '@material-ui/lab';

const Registrations = () => {
  const [processing, setProcessing] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const createUser = (e) => {
    e.preventDefault();
    setProcessing(true);
    if(!name || !email || !password){
      alert('Please fill all required details');
    }else{
      const payload = {
        name,
        email,
        password
      }
      Axios.post(`${cloudUrl}/createAppUser`, payload).then((res) => {
        setName('');
        setEmail('');
        setPassword('');
        setProcessing(false);
        
        alert('Created successfully.')
      }).catch(() => {
        alert('Some error occurred, try again.')
        setProcessing(false);
      })
    }
    
  }
  return(
    <DashboardLayout title="Register User">
      <Box p={2}>
        <Box mb={2}>
          <Alert severity="info" variant='outlined' >Enter user details to get register into our system, You can get that record into Users section.</Alert>
        </Box>
        <form onSubmit={createUser}>
          <Box mb={1}>
            <TextField 
              variant="outlined"
              fullWidth
              label="Name*"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          
          <Box mb={1}>
            <TextField 
              variant="outlined"
              fullWidth
              label="Email*"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />  
          </Box>
          
          <Box mb={2}>
            <TextField 
              variant="outlined"
              fullWidth
              label="Password*"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />  
          </Box>

          <Button
            variant="contained"  
            color="primary"
            type="submit"
            fullWidth
            disabled={processing}
          >
            Submit
          </Button>          
        </form>
      </Box>
    </DashboardLayout>
  )
}

export default Registrations;