import React from 'react';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from 'material-table';
import { Box, Button, Typography } from '@material-ui/core';
import AddMainCategory from './AddMainCategory';
import { firestore_db, storage } from '../../config/firebase_config';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import axios from 'axios';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    JSON: forwardRef((props, ref) => <CloudCircleIcon {...props} ref={ref} />)
};

export default class MainCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount = async () => {
        this.getData();
    };

    getData = async () => {
        let data = await firestore_db.collection('main_categories').get();
        this.setState({
            data:
                data.size > 0
                    ? data.docs.map(v => ({
                          id: v.id,
                          date: new Date(v.data().create_date).toDateString(),
                          ...v.data()
                      }))
                    : []
        });
    };

    generateJSON = async data => {
        console.log(data);

        if (data.id == '7wEabmxn6zifUjVncqhU') {
            //Movies
            alert('Build data for Movies from LIVE/Movies section');
        } else if (data.id == '68XpxBCdQhHiu2dag0ax') {
            //LIVE
            let docID = '68XpxBCdQhHiu2dag0ax';
            let getCategories = await firestore_db
                .collection('categories')
                .where('main_cat_id', 'array-contains', docID)
                .get();
            // console.log('[Categories]', getCategories);
            if (getCategories.size > 0) {
                let promises = [];
                getCategories.docs.map(async doc => {
                    promises.push(
                        new Promise(async resolve => {
                            resolve({
                                create_date: doc.data().create_date,
                                category_name: doc.data().category_name,
                                description: doc.data().description,
                                main_cat_id: doc.data().main_cat_id,
                                videos: await getVideosForCategories(doc.id)
                            });
                        })
                    );
                });

                let resolvePromises = await Promise.all(promises);
                console.log('[Promise resolved]');

                await new Promise(resolve => setTimeout(() => resolve(), 2000));

                console.log('SAVING DATA...', JSON.stringify(data));
                const obj = {
                    categories: resolvePromises
                };
                const blob = new Blob([JSON.stringify(obj, null, 2)], {
                    type: 'application/json'
                });
                var storageRef = storage.ref('APP_DATA').child('live.json');
                var file = blob; // use the Blob or File API
                storageRef
                    .put(file)
                    .then(function(snapshot) {
                        console.log('Uploaded a blob or file!');
                        alert('Data updated successfully.');
                    })
                    .catch(err => console.log(err));
            }
        } else if (data.id == 'wRU21WGm5xzFKuXsfPA1') {
            //VOD
            let retData = [];
            let getMainCategories = await firestore_db
                .collection('vod_main_categories')
                .orderBy('create_date', 'asc')
                .get();
            if (getMainCategories.size > 0) {
                let mainCatPromises = [];
                getMainCategories.docs.map(async (mcat, iDx) => {
                    let promise = new Promise(async resolve => {
                        let data = mcat.data();
                        let obj = {
                            category_name: data.category_name,
                            cat_img: data.cat_image,
                            sub_category: []
                        };
                        let getSubCategories = await firestore_db
                            .collection('vod_sub_categories')
                            .where(
                                'main_cat_id',
                                '==',
                                firestore_db.doc(
                                    `vod_main_categories/${mcat.id}`
                                )
                            )
                            .get();
                        if (getSubCategories.size > 0) {
                            let subCatPromises = [];
                            getSubCategories.docs.map(async (scat, si) => {
                                let tmpPromise = new Promise(async resolve => {
                                    let tmpObj = {
                                        series_name: scat.data().sub_cat_name,
                                        series: []
                                    };
                                    let getSubCategoriesSeries = await firestore_db
                                        .collection('vod_series')
                                        .where(
                                            'vod_sub_cat_id',
                                            '==',
                                            firestore_db.doc(
                                                `vod_sub_categories/${scat.id}`
                                            )
                                        )
                                        .get();
                                    if (getSubCategoriesSeries.size > 0) {
                                        let subCatSeriesPromises = [];
                                        getSubCategoriesSeries.docs.map(
                                            async (scats, ssci) => {
                                                let tmpSsciPromise = new Promise(
                                                    async resolve => {
                                                        let videoData = [];
                                                        tmpObj.series.push({
                                                            tmp_id: scats.id,
                                                            sub_cat_name: scats.data()
                                                                .sub_cat_name,
                                                            sub_cat_image_url: scats.data()
                                                                .sub_cat_image_url,
                                                            videos: videoData
                                                        });
                                                        resolve();
                                                    }
                                                );
                                                subCatSeriesPromises.push(
                                                    tmpSsciPromise
                                                );
                                                if (
                                                    getSubCategoriesSeries.docs
                                                        .length ==
                                                    ssci + 1
                                                ) {
                                                    await Promise.all(
                                                        subCatSeriesPromises
                                                    );
                                                }
                                            }
                                        );
                                    }
                                    obj.sub_category.push(tmpObj);
                                    resolve();
                                });
                                // retData.push(obj);
                                subCatPromises.push(tmpPromise);
                                if (getSubCategories.docs.length == si + 1) {
                                    await Promise.all(subCatPromises);
                                }
                            });
                        }
                        retData.push(obj);
                        setTimeout(() => resolve(), (iDx + 1) * 1000);
                    });
                    mainCatPromises.push(promise);
                    if (getMainCategories.docs.length == iDx + 1) {
                        await Promise.all(mainCatPromises);
                        console.log(retData);

                        let videoPromise = [];
                        retData.map(async (ret, iDx) => {
                            let promise = new Promise(async resolve => {
                                // let tmpObj = { ...ret }

                                let tmpPromises = [];
                                if (retData[iDx].sub_category.length == 0) {
                                    resolve();
                                }
                                retData[iDx].sub_category.map(async (s, i) => {
                                    s.series.map(async (ss, si) => {
                                        let tmp = new Promise(async resolve => {
                                            let getVideos = await firestore_db
                                                .collection('vod_video')
                                                .where(
                                                    'series_id',
                                                    '==',
                                                    firestore_db.doc(
                                                        `vod_series/${ss.tmp_id}`
                                                    )
                                                )
                                                .orderBy('create_date', 'desc')
                                                .get();
                                            let videoData = [];
                                            if (getVideos.size > 0) {
                                                getVideos.docs.map(v => {
                                                    let tmpData = v.data();
                                                    delete tmpData.series_id;
                                                    videoData.push(tmpData);
                                                });
                                            }
                                            retData[iDx].sub_category[i].series[
                                                si
                                            ].videos =
                                                videoData.length > 0
                                                    ? videoData.sort(function(
                                                          a,
                                                          b
                                                      ) {
                                                          var x = a.title;
                                                          var y = b.title;
                                                          return x > y
                                                              ? -1
                                                              : x < y
                                                              ? 1
                                                              : 0;
                                                      })
                                                    : [];
                                            resolve();
                                        });

                                        tmpPromises.push(tmp);

                                        if (s.series.length == si + 1) {
                                            await Promise.all(tmpPromises);
                                            resolve();
                                        }
                                    });
                                });
                            });

                            videoPromise.push(promise);
                            if (retData.length == iDx + 1) {
                                await Promise.all(videoPromise);
                                console.log('res 2');
                                setTimeout(async () => {
                                    const obj = {
                                        categories: retData
                                    };
                                    const blob = new Blob(
                                        [JSON.stringify(obj, null, 2)],
                                        {
                                            type: 'application/json'
                                        }
                                    );
                                    var storageRef = storage
                                        .ref('APP_DATA')
                                        .child('vod2.json');
                                    var file = blob; // use the Blob or File API
                                    storageRef
                                        .put(file)
                                        .then(function(snapshot) {
                                            console.log(
                                                'Uploaded a blob or file!'
                                            );
                                            alert('Data updated successfully.');
                                        })
                                        .catch(err => console.log(err));
                                }, 2000);
                            }
                        });
                    }
                });
            }
        }
    };

    render() {
        const { data } = this.state;

        return (
            <>
                <Box
                    mt={2}
                    mb={2}
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center'
                    }}>
                    <Typography style={{ flex: 3 }} variant="h2" component="h2">
                        Application Main Display Categories
                    </Typography>
                    {/* <AddMainCategory getData={this.getData}/> */}
                </Box>
                <MaterialTable
                    columns={[
                        { title: 'Category Name', field: 'category_name' },
                        { title: 'Description', field: 'description' },
                        { title: 'Create Date', field: 'date', type: 'date' }
                    ]}
                    data={data}
                    title=""
                    icons={tableIcons}
                    style={{
                        boxShadow: 'none !important'
                    }}
                    actions={[
                        {
                            icon: tableIcons.JSON,
                            tooltip: 'Make JSON',
                            onClick: (event, rowData) => {
                                this.generateJSON(rowData);
                            }
                        }
                    ]}
                    options={{
                        actionsColumnIndex: -1
                    }}
                />
            </>
        );
    }
}

const getVideosForCategories = async data => {
    return new Promise(async resolve => {
        let videos = await firestore_db
            .collection('videos')
            .where('categories', 'array-contains', data)
            .get();
        if (videos.size > 0) {
            let videoData = [],
                length = videos.docs.length;
            videos.docs.map((doc, iDx) => {
                videoData.push({
                    ...doc.data(),
                    duration:
                        doc.data().duration == ''
                            ? 0
                            : parseFloat(doc.data().duration)
                });
                if (length == iDx + 1) {
                    resolve(videoData);
                }
            });
        } else {
            resolve([]);
        }
    });
};

// let HOST =
//                        'https://us-central1-tv-trivision.cloudfunctions.net';
//                    // let HOST = 'http://localhost:5000/tv-trivision/us-central1';
//                    let URL = HOST + '/buildLiveJSON';
//                    const bodyOptions = {
//                        method: 'POST',
//                        body: JSON.stringify({
//                            typeId: data.id
//                        })
//                    };

//                    fetch(URL, bodyOptions)
//                        .then(async response => {
//                            let res = await response.json();
//                            console.log(res);
//                            if (res.status == 'DONE') {
//                                alert('New data updated');
//                            }
//                        })
//                        .then(serverErrors => {
//                            console.log(serverErrors);
//                        });

//                    // fetch(URL,{
//                    //     method: 'POST',
//                    //     headers:{
//                    //         'Access-Control-Allow-Origin' : '*',
//                    //     }
//                    // }).then((call) => {
//                    //     // console.log('[Response]',call);
//                    //     if(call.data == 'DONE'){
//                    //         alert('New data updated')
//                    //     }
//                    // });
