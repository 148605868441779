import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

const PlansSelector = ({onChange, defaultValue}) => {
  const optionsObj = {
    BASIC : {id: 'BASIC', title: 'Basic'},
    PREMIUM: {id: 'PREMIUM', title: 'Premium'}
  }
  const options = [
    {id: 'BASIC', title: 'Basic'},
    {id: 'PREMIUM', title: 'Premium'}
  ]

  return (
    <Autocomplete
      id="combo-box-demo"
      options={options}
      getOptionLabel={(option) => option.title}
      defaultValue={defaultValue ? Object.entries(defaultValue).filter((v) => v[1] === true).map(v => (optionsObj[v[0]])) : []}
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Plans" variant="outlined" size='small'/>}
      onChange={onChange}
      multiple
    />
  )
}

export default PlansSelector;